import Grid from "@mui/material/Grid";

const ProductInfo = () => {
  return (
    <Grid
      container
      sx={{
        minHeight: "calc(100vh - 113px)",
        p: "40px",
        display: "flex",
        flexDirection: "column",
      }}>
      <Grid item xs={12} sx={{ mb: "32px" }}>
        <h1>其他</h1>
        <p>其他。</p>
      </Grid>
    </Grid>
  );
};

export default ProductInfo;
