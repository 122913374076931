import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";

const StrategyFormPage = () => {
  return (
    <Box>
      <Grid container sx={{ p: "40px" }}>
        <Grid item xs={12}>
          <h1>策略表格</h1>
          <p>請填寫量表定義。</p>
        </Grid>
        <Grid item xs={12} sx={{ mb: "31px" }}>
          <Box>
            <List>
              <ListItem sx={{ borderBottom: "1px solid #00B388", my: 2 }}>
                <Grid container xs={12}>
                  <Grid item xs={1}>
                    <ListItemText primary="項目" />
                  </Grid>
                  <Grid item xs={8}>
                    <ListItemText primary="說明" />
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem sx={{ borderBottom: "1px solid #00B388", my: 2 }}>
                <Grid container xs={12}>
                  <Grid item xs={1}>
                    <ListItemText primary="風險序號" />
                  </Grid>
                  <Grid item xs={8}>
                    <ListItemText primary="RISK 1" />
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem sx={{ borderBottom: "1px solid #00B388", my: 2 }}>
                <Grid container xs={12}>
                  <Grid item xs={1}>
                    <ListItemText primary="風險位置" />
                  </Grid>
                  <Grid item xs={8}>
                    <ListItemText primary="本身營運" />
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem sx={{ borderBottom: "1px solid #00B388", my: 2 }}>
                <Grid container xs={12}>
                  <Grid item xs={1}>
                    <ListItemText primary="風險類型" />
                  </Grid>
                  <Grid item xs={8}>
                    <ListItemText primary="轉型風險" />
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem sx={{ borderBottom: "1px solid #00B388", my: 2 }}>
                <Grid container xs={12}>
                  <Grid item xs={1}>
                    <ListItemText primary="風險項目" />
                  </Grid>
                  <Grid item xs={8}>
                    <ListItemText primary="法規 - 提高溫室氣體排放定價" />
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem sx={{ borderBottom: "1px solid #00B388", my: 2 }}>
                <Grid container xs={12}>
                  <Grid item xs={1}>
                    <ListItemText primary="財務衝擊類型" />
                  </Grid>
                  <Grid item xs={8}>
                    <ListItemText primary="法規 - 直接成本增加" />
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem sx={{ borderBottom: "1px solid #00B388", my: 2 }}>
                <Grid container xs={12}>
                  <Grid item xs={1}>
                    <ListItemText primary="風險描述" />
                  </Grid>
                  <Grid item xs={8}>
                    <ListItemText primary="台灣溫室氣體減量與管理法通過，未來企業超過定額之溫室氣體排放每噸將課以新台幣100元之碳費，本公司將面臨營運直接成本增加。" />
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem sx={{ borderBottom: "1px solid #00B388", my: 2 }}>
                <Grid container xs={12}>
                  <Grid item xs={1}>
                    <ListItemText primary="時間範圍" />
                  </Grid>
                  <Grid item xs={8}>
                    <ListItemText primary="短期" />
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem sx={{ borderBottom: "1px solid #00B388", my: 2 }}>
                <Grid container xs={12}>
                  <Grid item xs={1}>
                    <ListItemText primary="可能性" />
                  </Grid>
                  <Grid item xs={8}>
                    <ListItemText primary="非常可能" />
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem sx={{ borderBottom: "1px solid #00B388", my: 2 }}>
                <Grid container xs={12}>
                  <Grid item xs={1}>
                    <ListItemText primary="影響程度" />
                  </Grid>
                  <Grid item xs={8}>
                    <ListItemText primary="中" />
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem sx={{ borderBottom: "1px solid #00B388", my: 2 }}>
                <Grid container xs={12}>
                  <Grid item xs={1}>
                    <ListItemText primary="改善方案" />
                  </Grid>
                  <Grid item xs={11}>
                    <TextField
                      label="請輸入改善方案"
                      value={"導入設備節能減碳。"}
                      size="small"
                      sx={{ width: "100%" }}
                      variant="outlined"
                      placeholder="例：0年"
                    />
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem sx={{ borderBottom: "1px solid #00B388", my: 2 }}>
                <Grid container xs={12}>
                  <Grid item xs={1}>
                    <ListItemText primary="潛在財務影響" />
                  </Grid>
                  <Grid item xs={11}>
                    <TextField
                      label="請輸入潛在財務影響"
                      value={"新台幣 $XXXXXXXX 元。"}
                      size="small"
                      sx={{ width: "100%" }}
                      variant="outlined"
                      placeholder="例：0年"
                    />
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem sx={{ borderBottom: "1px solid #00B388", my: 2 }}>
                <Grid container xs={12} spacing={2}>
                  <Grid item xs={1}>
                    <ListItemText primary="財務影響說明" />
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      label="請輸入財務影響說明"
                      value={
                        "本公司每年超過溫室氣體排放額度約XX萬噸，XX萬噸×100元=XXXXXXX元。"
                      }
                      size="small"
                      sx={{ width: "100%" }}
                      variant="outlined"
                      placeholder="例：0年"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      variant="contained"
                      sx={{
                        color: "#fff",
                        width: "96px",
                        height: "41px",
                        background:
                          "linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%), #00B388",
                        borderRadius: "15px",
                      }}
                    >
                      導入範本
                    </Button>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem sx={{ borderBottom: "1px solid #00B388", my: 2 }}>
              <Grid container xs={12} spacing={2} alignContent={"center"}>
                  <Grid item xs={1}>
                    <ListItemText primary="財務影響說明" />
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      label="請輸入財務影響說明"
                      multiline
                      value={
                        "本公司預計導入XX節能設備，預計每年減少XXXX度的用電，約等於減少XX萬噸的溫室氣體排放。XX節能設備約新台幣$XXXXXX元，分五年財務折舊攤提。"
                      }
                      size="small"
                      sx={{ width: "100%" }}
                      variant="outlined"
                      placeholder="例：0年"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      variant="contained"
                      sx={{
                        color: "#fff",
                        width: "96px",
                        height: "41px",
                        background:
                          "linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%), #00B388",
                        borderRadius: "15px",
                      }}
                    >
                      導入範本
                    </Button>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem sx={{ borderBottom: "1px solid #00B388", my: 2 }}>
              <Grid container xs={12}>
                  <Grid item xs={1}>
                    <ListItemText primary="風險應對成本" />
                  </Grid>
                  <Grid item xs={11}>
                    <TextField
                      label="請輸入風險應對成本"
                      value={"新台幣 $XXXXXXXX 元。"}
                      size="small"
                      sx={{ width: "100%" }}
                      variant="outlined"
                      placeholder="例：0年"
                    />
                  </Grid>
                </Grid>
              </ListItem>
            </List>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default StrategyFormPage;
