import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ResponsiveNetwork } from "@nivo/network";

import * as style from "../styles";
// import prod from "../assets/images/product.png";

const Network = () => {
  const data = {
    nodes: [
      {
        id: "原物料",
        height: 1,
        size: 57,
        color: "rgb(97, 205, 187)",
      },
      {
        id: "製程",
        height: 1,
        size: 57,
        color: "rgb(97, 205, 187)",
      },
      {
        id: "配銷/零售",
        height: 1,
        size: 57,
        color: "rgb(97, 205, 187)",
      },
      {
        id: "使用",
        height: 1,
        size: 57,
        color: "rgb(97, 205, 187)",
      },
      {
        id: "回收處理",
        height: 1,
        size: 57,
        color: "rgb(97, 205, 187)",
      },
      {
        id: "IC芯片",
        height: 20,
        size: 60,
        color: "rgb(244, 117, 96)",
      },
      {
        id: "處理器",
        height: 0,
        size: 50,
        color: "rgb(232, 193, 160)",
      },
      {
        id: "連接器",
        height: 0,
        size: 50,
        color: "rgb(232, 193, 160)",
      },
      {
        id: "外殼",
        height: 0,
        size: 50,
        color: "rgb(232, 193, 160)",
      },
      {
        id: "面板",
        height: 0,
        size: 50,
        color: "rgb(232, 193, 160)",
      },
      {
        id: "電池",
        height: 0,
        size: 50,
        color: "rgb(232, 193, 160)",
      },
      {
        id: "零件組裝",
        height: 0,
        size: 50,
        color: "rgb(232, 193, 160)",
      },
      {
        id: "模組組裝",
        height: 0,
        size: 50,
        color: "rgb(232, 193, 160)",
      },
      {
        id: "出貨檢驗",
        height: 0,
        size: 50,
        color: "rgb(232, 193, 160)",
      },
      {
        id: "包裝",
        height: 0,
        size: 50,
        color: "rgb(232, 193, 160)",
      },
      {
        id: "消費者使用",
        height: 0,
        size: 50,
        color: "rgb(232, 193, 160)",
      },
      {
        id: "廢棄物",
        height: 0,
        size: 50,
        color: "rgb(232, 193, 160)",
      },
      {
        id: "資源回收",
        height: 0,
        size: 50,
        color: "rgb(232, 193, 160)",
      },
      {
        id: "運輸",
        height: 0,
        size: 50,
        color: "rgb(232, 193, 160)",
      },
      {
        id: "貨運",
        height: 0,
        size: 50,
        color: "rgb(232, 193, 160)",
      },
      {
        id: "倉儲",
        height: 0,
        size: 50,
        color: "rgb(232, 193, 160)",
      },
      {
        id: "銷售",
        height: 0,
        size: 50,
        color: "rgb(232, 193, 160)",
      },
      {
        id: "水電",
        height: 0,
        size: 50,
        color: "rgb(232, 193, 160)",
      },
    ],
    links: [
      {
        source: "IC芯片",
        target: "原物料",
        distance: 170,
      },
      {
        source: "IC芯片",
        target: "製程",
        distance: 170,
      },
      {
        source: "IC芯片",
        target: "配銷/零售",
        distance: 170,
      },
      {
        source: "IC芯片",
        target: "使用",
        distance: 170,
      },
      {
        source: "IC芯片",
        target: "回收處理",
        distance: 170,
      },
      {
        source: "原物料",
        target: "處理器",
        distance: 220,
      },
      {
        source: "原物料",
        target: "連接器",
        distance: 220,
      },
      {
        source: "原物料",
        target: "外殼",
        distance: 220,
      },
      {
        source: "原物料",
        target: "面板",
        distance: 220,
      },
      {
        source: "原物料",
        target: "電池",
        distance: 220,
      },
      {
        source: "製程",
        target: "零件組裝",
        distance: 220,
      },
      {
        source: "製程",
        target: "模組組裝",
        distance: 220,
      },
      {
        source: "製程",
        target: "出貨檢驗",
        distance: 220,
      },
      {
        source: "製程",
        target: "包裝",
        distance: 220,
      },
      {
        source: "配銷/零售",
        target: "貨運",
        distance: 220,
      },
      {
        source: "配銷/零售",
        target: "倉儲",
        distance: 220,
      },
      {
        source: "配銷/零售",
        target: "銷售",
        distance: 220,
      },
      {
        source: "配銷/零售",
        target: "水電",
        distance: 220,
      },
      {
        source: "使用",
        target: "消費者使用",
        distance: 220,
      },
      {
        source: "使用",
        target: "廢棄物",
        distance: 220,
      },
      {
        source: "回收處理",
        target: "運輸",
        distance: 220,
      },
      {
        source: "回收處理",
        target: "資源回收",
        distance: 220,
      },
    ],
  };
  return (
    <Box
      sx={{
        background: "linear-gradient(180deg, #F7FFF8 60.35%, #D2FFD7 111.48%)",
        minHeight: "calc(100vh - 233px  - 64px - 16px)",
        width: "calc(100vw - 80px)",
        padding: "40px",
        display: "flex",
        flexDirection: "column",
        gap: "40px",
      }}>
      <Box>
        <Typography variant="h1" sx={style.headerTextStyle}>
          產品碳足跡
        </Typography>
      </Box>
      <Box sx={{}}>
        <Box>
          <Typography
            variant="h6"
            sx={(theme) => ({
              color: theme.palette.primary.dark,
              fontSize: "12px",
              fontWeight: 400,
            })}>
            單位產品碳排放量
          </Typography>
          <Typography
            sx={(theme) => ({
              fontSize: "32px",
              fontWeight: 700,
              ">span": {
                fontSize: "12px",
              },
            })}>
            23.8 <span>kg /CO2e</span>
          </Typography>
        </Box>
        {/* <Box
          sx={{
            position: "absolute",
            width: "30%",
            right: "20px",
            top: "-100px",

            zIndex: 2,
          }}>
          <img src={prod} alt="prod" style={{ width: "100%" }} />
        </Box>
        <Box
          sx={(theme) => ({
            position: "absolute",
            width: "60%",
            right: "1px",
            borderRadius: "60px",
            top: "-10px",
            height: "100px",
            background: theme.palette.primary.main,
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          })}></Box> */}
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "calc(100vh - 113px - 33px - 67px - 160px)",
        }}>
        <ResponsiveNetwork
          data={data}
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          linkDistance={(e) => e.distance}
          centeringStrength={0.3}
          repulsivity={6}
          nodeSize={(n) => n.size}
          activeNodeSize={(n) => 1.5 * n.size}
          nodeColor={(e) => e.color}
          nodeBorderWidth={1}
          nodeBorderColor={{
            from: "color",
            modifiers: [["darker", 0.8]],
          }}
          linkThickness={(n) => 2 + 2 * n.target.data.height}
          linkBlendMode="multiply"
          motionConfig="wobbly"
        />
      </Box>
    </Box>
  );
};

export default Network;
