import { useState } from "react";
import styled from "styled-components";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import DateRangeIcon from "@mui/icons-material/DateRange";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import demo_chart from "../assets/images/chart_demo.png";
import line_chart from "../assets/images/line_chart.png";

const EnergyManagementPage = () => {
  const [ openList, setOpenList ] = useState<string[]>([]);
  let current = new Date();
  let currentTime = `${current.getFullYear()}/${
    current.getMonth() + 1
  }/${current.getDate()} ${current.toLocaleTimeString()}`;

  const demo_chart_list = [
    {
      name: "PDC-001",
      value: 400,
      color: "#FBBB0A",
    },
    {
      name: "PDC-002",
      value: 400,
      color: "#FBBB0A",
    },
    {
      name: "PDC-003",
      value: 600,
      color: "#FBBB0A",
    },
    {
      name: "PDC-004",
      value: 500,
      color: "#FBBB0A",
    },
    {
      name: "PDC-005",
      value: 900,
      color: "#FBBB0A",
    },
  ];

  function handleOpenList(openKey:string){
    const updateOpenList = [ ...openList ];
    const index = updateOpenList.indexOf(openKey);

    if (index > -1) {
      removeItem(updateOpenList, openKey);
    }else{
      updateOpenList.push(openKey) ;
    }

    setOpenList(updateOpenList);
  }

  function removeItem<T>(arr: Array<T>, value: T): Array<T> { 
    const index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }

  function handleCheckOpenList(key:string){
    return openList.includes(key);
  }

  return (
    <Box>
      <Grid container sx={{ p: "40px" }}>
        <Grid container direction={"row"} justifyContent={"space-between"}>
          <Grid item sx={{ mb: "32px" }}>
            <h1>即時儀表板 | 能源管理</h1>
          </Grid>
          <Grid item sx={{ mb: "32px" }}>
            <h1>{currentTime}</h1>
          </Grid>
        </Grid>
        <Grid container direction={"row"}>
          <Grid item xs={6} md={6} sx={{ mb: "32px" }}>
            <Grid container xs={12} sx={{ mb: "32px" }}>
              <Grid container rowGap={2}>
                <h4>即時電力指標</h4>
                <Grid container xs={12}>
                  <Grid xs={3}>
                    <StyledCard
                      sx={{
                        width: "300px",
                        height: "300px",
                        background:
                          "linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%), #FBBB0A !important",
                        ">h1": {
                          color: "#000000 !important",
                        },
                      }}
                    >
                      <p>全廠用電</p>
                      <h1>1500 kW/H</h1>
                    </StyledCard>
                  </Grid>
                  <Grid xs={3}>
                    <StyledCard sx={{ width: "300px", height: "300px" }}>
                      <p>PDC-001</p>
                      <h1>500 kW/H</h1>
                    </StyledCard>
                  </Grid>
                  <Grid xs={3}>
                    <StyledCard sx={{ width: "300px", height: "300px" }}>
                      <p>PDC-002</p>
                      <h1>400 kW/H</h1>
                    </StyledCard>
                  </Grid>
                </Grid>
                <Grid container xs={12}>
                  <Grid xs={3}>
                    <StyledCard
                      sx={{
                        width: "300px",
                        height: "300px",
                        ">h1": {
                          color: "#FBBB0A !important",
                        },
                      }}
                    >
                      <p>PDC-003</p>
                      <h1>600 kW/H</h1>
                    </StyledCard>
                  </Grid>
                  <Grid xs={3}>
                    <StyledCard
                      sx={{
                        width: "300px",
                        height: "300px",
                        ">h1": {
                          color: "#FBBB0A !important",
                        },
                      }}
                    >
                      <p>PDC-004</p>
                      <h1>500 kW/H</h1>
                    </StyledCard>
                  </Grid>
                  <Grid xs={3}>
                    <StyledCard
                      sx={{
                        width: "300px",
                        height: "300px",
                        backgroundColor: "#FFF7F5 !important",
                        ">h1": {
                          color: "#FF8468 !important",
                        },
                      }}
                    >
                      <p>PDC-006</p>
                      <h1>900 kW/H</h1>
                    </StyledCard>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container xs={12} direction={"column"} spacing={10}>
              <Grid item>
                <h4>即時資訊電表的用電量比例</h4>
              </Grid>
              <Grid item>
                <Grid
                  container
                  justifyContent={"center"}
                  alignContent={"center"}
                >
                  <img src={demo_chart} alt="demo_chart" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} md={6} sx={{ mb: "32px" }}>
            <Grid container xs={12} spacing={2} alignItems={"center"}>
              <Grid item>
                <h4>電表即時資訊 </h4>
              </Grid>
              <Grid item>
                <DateRangeIcon sx={{ color: "#00b388" }} />
              </Grid>
              <Grid item>
                <Typography
                  sx={{
                    background:
                      "linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%), #00B388",
                    borderRadius: "8px",
                    padding: "4px 8px",
                    color: "#fff",
                  }}
                >
                  依即時數據排序
                </Typography>
              </Grid>
            </Grid>
            <Grid container xs={12} spacing={2} sx={{ mt: "16px" }}>
              {demo_chart_list.map((chart) => (
                <Grid item key={chart.name}>
                  <StyleCollapsedCard onClick={()=>handleOpenList(chart.name)}>
                    <Grid container>
                      <Grid container alignItems={"center"} spacing={2}>
                        <Grid item>
                          {handleCheckOpenList(chart.name) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </Grid>
                        <Grid item>
                          <p>[ {chart.name} 用電量趨勢圖 ]</p>
                          <p>製程產線說明文字文字文字</p>
                        </Grid>
                        <Grid item>
                          <h1 style={{ color: `${chart.color}` }}>
                            {chart.value}kW
                          </h1>
                        </Grid>
                      </Grid>
                      <Collapse in={handleCheckOpenList(chart.name)}>
                        <Box
                          sx={{
                            width: "500px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "300px",
                          }}
                        >
                          <img src={line_chart} alt={"demo"} />
                        </Box>
                      </Collapse>
                    </Grid>
                  </StyleCollapsedCard>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 159px;
  height: 60px;
  background: #ffffff;
  border-radius: 15px;
  box-shadow: none;
  padding: 10px;
`;

const StyleCollapsedCard = styled(StyledCard)`
  width: 510px;
  height: inherit;
  padding: 20px;
`;

export default EnergyManagementPage;
