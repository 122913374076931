import { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import * as style from "../../styles";

const MaterialPhase = () => {
  const [numsOfRows, setNumsOfRows] = useState(2);

  const handleAddRow = () => {
    setNumsOfRows(numsOfRows + 1);
  };

  const handleDeleteRow = () => {
    if (numsOfRows > 0) {
      setNumsOfRows(numsOfRows - 1);
    }
  };

  return (
    <Grid
      container
      sx={{
        minHeight: "calc(100vh - 113px)",
        p: "40px",
        display: "flex",
        flexDirection: "column",
      }}>
      <Grid item sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        <Typography variant="h1" sx={style.headerTextStyle}>
          原物料階段
        </Typography>
        <Typography>
          包含直接原料、間接原料、設備耗材、包裝材料、廠務投入、污染防治投入及個原物料之運輸碳排放。
        </Typography>
      </Grid>
      <Grid item>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}>
          <Box>
            <Typography
              variant="h6"
              sx={(theme) => ({
                fontFamily: "Noto Sans TC",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "33px",
                letterSpacing: "0.15px",
                color: theme.palette.primary.dark,
              })}>
              填寫盤查表單
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              sx={(theme) => ({
                fontWeight: 500,
                fontSize: "13px",
                lineHeight: "22px",
                letterSpacing: "0.46px",
                color: theme.palette.primary.main,
              })}>
              使用Excel模板填寫：
            </Typography>
            <Button
              sx={(theme) => ({
                ...style.textButtonStyle,
                color: theme.palette.primary.main,
                lineHeight: "22px",
                transform: "translateY(-2px)",
              })}>
              模板下載
            </Button>
            <Typography
              sx={(theme) => ({
                fontWeight: 500,
                fontSize: "13px",
                lineHeight: "22px",
                letterSpacing: "0.46px",
                color: theme.palette.primary.main,
              })}>
              |
            </Typography>
            <Button
              sx={(theme) => ({
                ...style.textButtonStyle,
                color: theme.palette.primary.main,
                lineHeight: "22px",
                transform: "translateY(-2px)",
              })}>
              上傳已填寫檔案
            </Button>
          </Box>
        </Box>
        <Box>
          <TableContainer sx={{ width: "calc(100vw - 80px)" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead
                sx={(theme) => ({
                  "& .MuiTableRow-root": {
                    "& .MuiTableCell-root": {
                      backgroundColor: "rgba(0,0,0,0)",
                      color: theme.palette.primary.dark,
                      fontWeight: 600,
                      padding: "8px",
                    },
                  },
                })}>
                <TableRow>
                  <TableCell
                    sx={{
                      width: `5%`,
                    }}>
                    中文名稱
                  </TableCell>
                  <TableCell
                    sx={{
                      width: `8%`,
                    }}>
                    物質或成份名稱
                  </TableCell>
                  <TableCell
                    sx={{
                      width: `15%`,
                    }}>
                    盤查期間內原料總投入單位量
                  </TableCell>
                  <TableCell
                    sx={{
                      width: `5%`,
                    }}>
                    分配比例
                  </TableCell>
                  <TableCell
                    sx={{
                      width: `5%`,
                    }}>
                    分配說明
                  </TableCell>
                  <TableCell
                    sx={{
                      width: `5%`,
                    }}>
                    供應商名稱
                  </TableCell>
                  <TableCell
                    sx={{
                      width: `5%`,
                    }}>
                    供應商地址
                  </TableCell>
                  <TableCell
                    sx={{
                      width: `5%`,
                    }}>
                    陸運距離(KM)
                  </TableCell>
                  <TableCell
                    sx={{
                      width: `5%`,
                    }}>
                    海運距離(KM)
                  </TableCell>
                  <TableCell
                    sx={{
                      width: `5%`,
                    }}>
                    空運距離(KM)
                  </TableCell>
                  <TableCell
                    sx={{
                      width: `5%`,
                    }}>
                    係數編號
                  </TableCell>
                  <TableCell
                    sx={{
                      width: `5%`,
                    }}>
                    備註
                  </TableCell>
                  <TableCell
                    sx={{
                      width: `5%`,
                    }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array(numsOfRows)
                  .fill(1)
                  .map((_, index) => {
                    return (
                      <TableRow hover tabIndex={-1} key={index}>
                        <TableCell
                          sx={{
                            width: `5%`,
                          }}>
                          <TextField
                            label="名稱"
                            focused
                            sx={{
                              background: "white",
                            }}
                            fullWidth
                            size="small"
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            width: `8%`,
                          }}>
                          <TextField
                            label="名稱"
                            focused
                            sx={{
                              background: "white",
                            }}
                            fullWidth
                            size="small"
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            width: `15%`,
                          }}>
                          <TextField
                            label="名稱"
                            focused
                            sx={{
                              background: "white",
                            }}
                            fullWidth
                            size="small"
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            width: `5%`,
                          }}>
                          <TextField
                            label="名稱"
                            focused
                            sx={{
                              background: "white",
                            }}
                            fullWidth
                            size="small"
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            width: `8%`,
                          }}>
                          <TextField
                            label="名稱"
                            focused
                            sx={{
                              background: "white",
                            }}
                            fullWidth
                            size="small"
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            width: `5%`,
                          }}>
                          <TextField
                            label="名稱"
                            focused
                            sx={{
                              background: "white",
                            }}
                            fullWidth
                            size="small"
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            width: `5%`,
                          }}>
                          <TextField
                            label="名稱"
                            focused
                            sx={{
                              background: "white",
                            }}
                            fullWidth
                            size="small"
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            width: `5%`,
                          }}>
                          <TextField
                            label="名稱"
                            focused
                            sx={{
                              background: "white",
                            }}
                            fullWidth
                            size="small"
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            width: `5%`,
                          }}>
                          <TextField
                            label="名稱"
                            focused
                            sx={{
                              background: "white",
                            }}
                            fullWidth
                            size="small"
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            width: `5%`,
                          }}>
                          <TextField
                            label="名稱"
                            focused
                            sx={{
                              background: "white",
                            }}
                            fullWidth
                            size="small"
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            width: `5%`,
                          }}>
                          <TextField
                            label="名稱"
                            focused
                            sx={{
                              background: "white",
                            }}
                            fullWidth
                            size="small"
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            width: `5%`,
                          }}>
                          <TextField
                            label="名稱"
                            focused
                            sx={{
                              background: "white",
                            }}
                            fullWidth
                            size="small"
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            width: `5%`,
                          }}>
                          <Button
                            onClick={handleDeleteRow}
                            sx={(theme) => ({
                              ...style.textButtonStyle,
                              color: theme.palette.error.main,
                            })}>
                            刪除
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box>
          <Button onClick={handleAddRow} sx={style.textButtonStyle}>
            + 新增一列
          </Button>
        </Box>
      </Grid>
      <Grid item>
        <Box
          sx={{
            width: "calc(100vw - 80px)",
            display: "flex",
            justifyContent: "center",
          }}>
          <Button sx={style.containedButtonStyle}>儲存</Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default MaterialPhase;
