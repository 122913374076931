import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";

const ScaleDefinitionPage = () => {
  return (
    <Box>
      <Grid container sx={{ p: "40px" }}>
        <Grid item xs={12} sx={{ mb: "32px" }}>
          <h1>量表定義</h1>
          <p>請填寫量表定義。</p>
        </Grid>
        <Grid item xs={12} sx={{ mb: "32px" }}>
          <Grid container>
            <Grid item xs={12}>
              <h4>設定短中長期目標</h4>
            </Grid>
            <Grid container xs={12} sx={{ width: "100%", mb: 4 }}>
              <FormControl sx={{ width: "100%" }}>
                <Grid
                  container
                  direction={"row"}
                  xs={12}
                  sx={{ width: "100%" }}
                  spacing={2}
                >
                  <Grid item xs={3}>
                    <p>短期（起）</p>
                    <TextField
                      label="填空題"
                      value={"例：0年"}
                      size="small"
                      sx={{ width: "100%" }}
                      variant="standard"
                      placeholder="例：0年"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <p>中期(起)</p>
                    <TextField
                      label="填空題"
                      value={"例：3年"}
                      size="small"
                      sx={{ width: "100%" }}
                      variant="standard"
                      placeholder="例：0年"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <p>長期(起)</p>
                    <TextField
                      label="填空題"
                      value={"例：5年"}
                      size="small"
                      sx={{ width: "100%" }}
                      variant="standard"
                      placeholder="例：0年"
                    />
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>
            <Grid container xs={12} sx={{ width: "100%" }}>
              <FormControl sx={{ width: "100%" }}>
                <Grid
                  container
                  direction={"row"}
                  xs={12}
                  sx={{ width: "100%" }}
                  spacing={2}
                >
                  <Grid item xs={3}>
                    <p>短期（起）</p>
                    <TextField
                      label="填空題"
                      value={"例：0年"}
                      size="small"
                      sx={{ width: "100%" }}
                      variant="standard"
                      placeholder="例：0年"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <p>中期(起)</p>
                    <TextField
                      label="填空題"
                      value={"例：3年"}
                      size="small"
                      sx={{ width: "100%" }}
                      variant="standard"
                      placeholder="例：0年"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <p>長期(起)</p>
                    <TextField
                      label="填空題"
                      value={"例：5年"}
                      size="small"
                      sx={{ width: "100%" }}
                      variant="standard"
                      placeholder="例：0年"
                    />
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid container xs={12} sx={{ mb: "40px" }}>
          <Grid container>
            <Grid item xs={12}>
              <h4>設定量表定義</h4>
            </Grid>
            <Grid
              container
              xs={12}
              sx={{ width: "100%", mb: 1 }}
              direction={"row"}
              spacing={2}
            >
              <Grid item xs={6}>
                <Grid item>
                  <h5>風險可能性量表</h5>
                </Grid>
                <Grid item xs={10} sx={{ mb: "16px" }}>
                  <p>評估方式</p>
                  <TextField
                    label="填空題"
                    value={"例：5年"}
                    size="small"
                    sx={{ width: "100%" }}
                    variant="standard"
                    placeholder="例：0年"
                  />
                </Grid>
                <Grid item xs={10} sx={{ mb: "16px" }}>
                  <p>非常大</p>
                  <TextField
                    label="填空題"
                    value={"例：每月"}
                    size="small"
                    sx={{ width: "100%" }}
                    variant="standard"
                    placeholder="例：0年"
                  />
                </Grid>
                <Grid item xs={10} sx={{ mb: "16px" }}>
                  <p>大</p>
                  <TextField
                    label="填空題"
                    value={"例：每季"}
                    size="small"
                    sx={{ width: "100%" }}
                    variant="standard"
                    placeholder="例：0年"
                  />
                </Grid>
                <Grid item xs={10} sx={{ mb: "16px" }}>
                  <p>普通</p>
                  <TextField
                    label="填空題"
                    value={"例：每年"}
                    size="small"
                    sx={{ width: "100%" }}
                    variant="standard"
                    placeholder="例：0年"
                  />
                </Grid>
                <Grid item xs={10} sx={{ mb: "16px" }}>
                  <p>小</p>
                  <TextField
                    label="填空題"
                    value={"例：3年"}
                    size="small"
                    sx={{ width: "100%" }}
                    variant="standard"
                    placeholder="例：0年"
                  />
                </Grid>
                <Grid item xs={10} sx={{ mb: "16px" }}>
                  <p>非常小</p>
                  <TextField
                    label="填空題"
                    value={"例：3年以上"}
                    size="small"
                    sx={{ width: "100%" }}
                    variant="standard"
                    placeholder="例：0年"
                  />
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid item >
                  <h5>財務衝擊量表</h5>
                </Grid>
                <Grid item xs={10} sx={{ mb: "16px" }}>
                  <p>評估方式</p>
                  <TextField
                    label="填空題"
                    value={"例：5年"}
                    size="small"
                    sx={{ width: "100%" }}
                    variant="standard"
                    placeholder="例：0年"
                  />
                </Grid>
                <Grid item xs={10} sx={{ mb: "16px" }}>
                  <p>非常大</p>
                  <TextField
                    label="填空題"
                    value={"例：每月"}
                    size="small"
                    sx={{ width: "100%" }}
                    variant="standard"
                    placeholder="例：0年"
                  />
                </Grid>
                <Grid item xs={10} sx={{ mb: "16px" }}>
                  <p>大</p>
                  <TextField
                    label="填空題"
                    value={"例：每季"}
                    size="small"
                    sx={{ width: "100%" }}
                    variant="standard"
                    placeholder="例：0年"
                  />
                </Grid>
                <Grid item xs={10} sx={{ mb: "16px" }}>
                  <p>普通</p>
                  <TextField
                    label="填空題"
                    value={"例：每年"}
                    size="small"
                    sx={{ width: "100%" }}
                    variant="standard"
                    placeholder="例：0年"
                  />
                </Grid>
                <Grid item xs={10} sx={{ mb: "16px" }}>
                  <p>小</p>
                  <TextField
                    label="填空題"
                    value={"例：3年"}
                    size="small"
                    sx={{ width: "100%" }}
                    variant="standard"
                    placeholder="例：0年"
                  />
                </Grid>
                <Grid item xs={10} sx={{ mb: "16px" }}>
                  <p>非常小</p>
                  <TextField
                    label="填空題"
                    value={"例：3年以上"}
                    size="small"
                    sx={{ width: "100%" }}
                    variant="standard"
                    placeholder="例：0年"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ScaleDefinitionPage;
