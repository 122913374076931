import styled from "styled-components";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import AddIcon from "@mui/icons-material/Add";
import PauseIcon from "@mui/icons-material/Pause";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import energy_icon from "../assets/images/energy_icon.png";
import heat_management_icon from "../assets/images/heat_management_icon.png";
import carbon_card_bg from "../assets/images/carbon_card_bg.png";

const CarbonEmissionPage = () => {
  let current = new Date();
  let currentTime = `${current.getFullYear()}/${
    current.getMonth() + 1
  }/${current.getDate()} ${current.toLocaleTimeString()}`;

  return (
    <Box>
      <Grid container sx={{ p: "40px" }}>
        <Grid container direction={"row"} justifyContent={"space-between"}>
          <Grid item sx={{ mb: "32px" }}>
            <h1>即時儀表板 | 機房碳排放</h1>
          </Grid>
          <Grid item sx={{ mb: "32px" }}>
            <h1>{currentTime}</h1>
          </Grid>
        </Grid>
        <Grid container spacing={5} alignItems={"center"} sx={{ mb: "40px" }}>
          <Grid item>
            <HeaderCard>
              <Grid
                container
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                spacing={5}
              >
                <Grid item>
                  <Box>
                    <img src={energy_icon} alt={"energy icon"} />
                  </Box>
                </Grid>
                <Grid item>
                  <p>電源相關用電量</p>
                  <Grid container direction={"row"} alignItems={"flex-end"}>
                    <h1 style={{ fontSize: "47px", color: "#000000" }}>
                      1,833
                    </h1>{" "}
                    <p style={{ margin: "0px", paddingLeft: "5px" }}>度</p>
                  </Grid>
                </Grid>
              </Grid>
            </HeaderCard>
          </Grid>
          <Grid item>
            <AddIcon
              sx={{
                color: "#016665",
              }}
            />
          </Grid>
          <Grid item>
            <HeaderCard>
              <Grid
                container
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                spacing={5}
              >
                <Grid item>
                  <Box>
                    <img
                      src={heat_management_icon}
                      alt={"heat management icon"}
                    />
                  </Box>
                </Grid>
                <Grid item>
                  <p>電源相關用電量</p>
                  <Grid container direction={"row"} alignItems={"flex-end"}>
                    <h1 style={{ fontSize: "47px", color: "#000000" }}>
                      1,833
                    </h1>{" "}
                    <p style={{ margin: "0px", paddingLeft: "5px" }}>度</p>
                  </Grid>
                </Grid>
              </Grid>
            </HeaderCard>
          </Grid>
          <Grid item>
            <PauseIcon
              sx={{
                color: "#016665",
                transform: "rotate(90deg)",
              }}
            />
          </Grid>
          <Grid item>
            <HeaderCard
              style={{
                background:
                  "linear-gradient(272.98deg, #EFFFAE 6.15%, #016665 93.16%)",
                position: "relative",
              }}
            >
              <Grid
                container
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                spacing={5}
              >
                <Grid item>
                  <p style={{ color: "#ffffff" }}>[ CO2 總排碳 ]</p>
                  <Grid container direction={"row"} alignItems={"flex-end"}>
                    <h1 style={{ fontSize: "47px", color: "#ffffff" }}>
                      1,833
                    </h1>{" "}
                    <p
                      style={{
                        margin: "0px",
                        paddingLeft: "5px",
                        color: "#ffffff",
                      }}
                    >
                      CO2e / 度
                    </p>
                  </Grid>
                </Grid>
              </Grid>
              <Box>
                <img
                  src={carbon_card_bg}
                  alt={"carbon card bg"}
                  style={{
                    position: "absolute",
                    bottom: "0px",
                    right: "0px",
                  }}
                />
              </Box>
            </HeaderCard>
          </Grid>
        </Grid>
        <Grid container spacing={5} alignItems={"center"} justifyContent={"space-between"} sx={{ mb: "40px" }}>
          <Grid item>
            <Grid
              container
              alignItems={"center"}
              justifyContent={"center"}
              sx={{ position: "relative", width: "220px", mb: "10px" }}
            >
              <Box sx={{ position: "absolute", right: "-20px" }}>
                <ArrowForwardIosIcon sx={{ color: "#016665" }} />
              </Box>
              <p>電源供應器</p>
            </Grid>
            <ItemCard>
              <Grid
                container
                direction={"column"}
                spacing={1}
                justifyContent={"center"}
                alignItems={"center"}
                sx={{ height: "188px" }}
              >
                <Grid item>
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "12px",
                    }}
                  >
                    [ 用電 ]
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      width: "61px",
                      height: "24px",
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: "30px",
                      lineHeight: "24px",
                      color: "#016665",
                    }}
                  >
                    69.2
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "12px",
                    }}
                  >
                    度
                  </Typography>
                </Grid>
                <Grid item>
                  <Divider
                    sx={{ border: "1px solid #00B388", width: "140px" }}
                  />
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "12px",
                    }}
                  >
                    [ 碳排量 ]
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "18px",
                      lineHeight: "24px",
                    }}
                  >
                    kgCO2e/ton
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "12px",
                    }}
                  >
                    kgCO2e/ton
                  </Typography>
                </Grid>
              </Grid>
            </ItemCard>
          </Grid>
          <Grid item>
            <Grid
              container
              alignItems={"center"}
              justifyContent={"center"}
              sx={{ position: "relative", width: "220px", mb: "10px" }}
            >
              <Box sx={{ position: "absolute", right: "-20px" }}>
                <ArrowForwardIosIcon sx={{ color: "#016665" }} />
              </Box>
              <p>UPS</p>
            </Grid>
            <ItemCard>
              <Grid
                container
                direction={"column"}
                spacing={1}
                justifyContent={"center"}
                alignItems={"center"}
                sx={{ height: "188px" }}
              >
                <Grid item>
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "12px",
                    }}
                  >
                    [ 用電 ]
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      width: "61px",
                      height: "24px",
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: "30px",
                      lineHeight: "24px",
                      color: "#FBBB0A",
                    }}
                  >
                    69.2
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "12px",
                    }}
                  >
                    度
                  </Typography>
                </Grid>
                <Grid item>
                  <Divider
                    sx={{ border: "1px solid #FBBB0A", width: "140px" }}
                  />
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "12px",
                    }}
                  >
                    [ 碳排量 ]
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "18px",
                      lineHeight: "24px",
                    }}
                  >
                    kgCO2e/ton
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "12px",
                    }}
                  >
                    kgCO2e/ton
                  </Typography>
                </Grid>
              </Grid>
            </ItemCard>
          </Grid>
          <Grid item>
            <Grid
              container
              alignItems={"center"}
              justifyContent={"center"}
              sx={{ position: "relative", width: "220px", mb: "10px" }}
            >
              <Box sx={{ position: "absolute", right: "-20px" }}>
                <ArrowForwardIosIcon sx={{ color: "#016665" }} />
              </Box>
              <p>電源分配器</p>
            </Grid>
            <ItemCard>
              <Grid
                container
                direction={"column"}
                spacing={1}
                justifyContent={"center"}
                alignItems={"center"}
                sx={{ height: "188px" }}
              >
                <Grid item>
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "12px",
                    }}
                  >
                    [ 用電 ]
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      width: "61px",
                      height: "24px",
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: "30px",
                      lineHeight: "24px",
                      color: "#016665",
                    }}
                  >
                    69.2
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "12px",
                    }}
                  >
                    度
                  </Typography>
                </Grid>
                <Grid item>
                  <Divider
                    sx={{ border: "1px solid #00B388", width: "140px" }}
                  />
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "12px",
                    }}
                  >
                    [ 碳排量 ]
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "18px",
                      lineHeight: "24px",
                    }}
                  >
                    kgCO2e/ton
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "12px",
                    }}
                  >
                    kgCO2e/ton
                  </Typography>
                </Grid>
              </Grid>
            </ItemCard>
          </Grid>
          <Grid item>
            <Grid
              container
              alignItems={"center"}
              justifyContent={"center"}
              sx={{ position: "relative", width: "220px", mb: "10px" }}
            >
              <Box sx={{ position: "absolute", right: "-20px" }}>
                <ArrowForwardIosIcon sx={{ color: "#016665" }} />
              </Box>
              <p>熱管理系統</p>
            </Grid>
            <ItemCard>
              <Grid
                container
                direction={"column"}
                spacing={1}
                justifyContent={"center"}
                alignItems={"center"}
                sx={{ height: "188px" }}
              >
                <Grid item>
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "12px",
                    }}
                  >
                    [ 用電 ]
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      width: "61px",
                      height: "24px",
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: "30px",
                      lineHeight: "24px",
                      color: "#016665",
                    }}
                  >
                    69.2
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "12px",
                    }}
                  >
                    度
                  </Typography>
                </Grid>
                <Grid item>
                  <Divider
                    sx={{ border: "1px solid #00B388", width: "140px" }}
                  />
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "12px",
                    }}
                  >
                    [ 碳排量 ]
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "18px",
                      lineHeight: "24px",
                    }}
                  >
                    kgCO2e/ton
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "12px",
                    }}
                  >
                    kgCO2e/ton
                  </Typography>
                </Grid>
              </Grid>
            </ItemCard>
          </Grid>
          <Grid item>
            <Grid
              container
              alignItems={"center"}
              justifyContent={"center"}
              sx={{ position: "relative", width: "220px", mb: "10px" }}
            >
              <p>監控系統</p>
            </Grid>
            <ItemCard>
              <Grid
                container
                direction={"column"}
                spacing={1}
                justifyContent={"center"}
                alignItems={"center"}
                sx={{ height: "188px" }}
              >
                <Grid item>
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "12px",
                    }}
                  >
                    [ 用電 ]
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      width: "61px",
                      height: "24px",
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: "30px",
                      lineHeight: "24px",
                      color: "#016665",
                    }}
                  >
                    69.2
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "12px",
                    }}
                  >
                    度
                  </Typography>
                </Grid>
                <Grid item>
                  <Divider
                    sx={{ border: "1px solid #00B388", width: "140px" }}
                  />
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "12px",
                    }}
                  >
                    [ 碳排量 ]
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "18px",
                      lineHeight: "24px",
                    }}
                  >
                    kgCO2e/ton
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "12px",
                    }}
                  >
                    kgCO2e/ton
                  </Typography>
                </Grid>
              </Grid>
            </ItemCard>
          </Grid>
        </Grid>
        <Grid container spacing={5} alignItems={"center"} justifyContent={"space-between"}>
          <Grid item>
            <Grid
              container
              alignItems={"center"}
              justifyContent={"center"}
              sx={{ position: "relative", width: "220px", mb: "10px" }}
            >
              <Box sx={{ position: "absolute", right: "-20px" }}>
                <ArrowForwardIosIcon sx={{ color: "#016665" }} />
              </Box>
              <p>電源供應器</p>
            </Grid>
            <ItemCard>
              <Grid
                container
                direction={"column"}
                spacing={1}
                justifyContent={"center"}
                alignItems={"center"}
                sx={{ height: "188px" }}
              >
                <Grid item>
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "12px",
                    }}
                  >
                    [ 用電 ]
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      width: "61px",
                      height: "24px",
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: "30px",
                      lineHeight: "24px",
                      color: "#016665",
                    }}
                  >
                    69.2
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "12px",
                    }}
                  >
                    度
                  </Typography>
                </Grid>
                <Grid item>
                  <Divider
                    sx={{ border: "1px solid #00B388", width: "140px" }}
                  />
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "12px",
                    }}
                  >
                    [ 碳排量 ]
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "18px",
                      lineHeight: "24px",
                    }}
                  >
                    kgCO2e/ton
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "12px",
                    }}
                  >
                    kgCO2e/ton
                  </Typography>
                </Grid>
              </Grid>
            </ItemCard>
          </Grid>
          <Grid item>
            <Grid
              container
              alignItems={"center"}
              justifyContent={"center"}
              sx={{ position: "relative", width: "220px", mb: "10px" }}
            >
              <Box sx={{ position: "absolute", right: "-20px" }}>
                <ArrowForwardIosIcon sx={{ color: "#016665" }} />
              </Box>
              <p>UPS</p>
            </Grid>
            <ItemCard>
              <Grid
                container
                direction={"column"}
                spacing={1}
                justifyContent={"center"}
                alignItems={"center"}
                sx={{ height: "188px" }}
              >
                <Grid item>
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "12px",
                    }}
                  >
                    [ 用電 ]
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      width: "61px",
                      height: "24px",
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: "30px",
                      lineHeight: "24px",
                      color: "#FBBB0A",
                    }}
                  >
                    69.2
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "12px",
                    }}
                  >
                    度
                  </Typography>
                </Grid>
                <Grid item>
                  <Divider
                    sx={{ border: "1px solid #FBBB0A", width: "140px" }}
                  />
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "12px",
                    }}
                  >
                    [ 碳排量 ]
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "18px",
                      lineHeight: "24px",
                    }}
                  >
                    kgCO2e/ton
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "12px",
                    }}
                  >
                    kgCO2e/ton
                  </Typography>
                </Grid>
              </Grid>
            </ItemCard>
          </Grid>
          <Grid item>
            <Grid
              container
              alignItems={"center"}
              justifyContent={"center"}
              sx={{ position: "relative", width: "220px", mb: "10px" }}
            >
              <Box sx={{ position: "absolute", right: "-20px" }}>
                <ArrowForwardIosIcon sx={{ color: "#016665" }} />
              </Box>
              <p>電源分配器</p>
            </Grid>
            <ItemCard>
              <Grid
                container
                direction={"column"}
                spacing={1}
                justifyContent={"center"}
                alignItems={"center"}
                sx={{ height: "188px" }}
              >
                <Grid item>
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "12px",
                    }}
                  >
                    [ 用電 ]
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      width: "61px",
                      height: "24px",
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: "30px",
                      lineHeight: "24px",
                      color: "#016665",
                    }}
                  >
                    69.2
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "12px",
                    }}
                  >
                    度
                  </Typography>
                </Grid>
                <Grid item>
                  <Divider
                    sx={{ border: "1px solid #00B388", width: "140px" }}
                  />
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "12px",
                    }}
                  >
                    [ 碳排量 ]
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "18px",
                      lineHeight: "24px",
                    }}
                  >
                    kgCO2e/ton
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "12px",
                    }}
                  >
                    kgCO2e/ton
                  </Typography>
                </Grid>
              </Grid>
            </ItemCard>
          </Grid>
          <Grid item>
            <Grid
              container
              alignItems={"center"}
              justifyContent={"center"}
              sx={{ position: "relative", width: "220px", mb: "10px" }}
            >
              <Box sx={{ position: "absolute", right: "-20px" }}>
                <ArrowForwardIosIcon sx={{ color: "#016665" }} />
              </Box>
              <p>熱管理系統</p>
            </Grid>
            <ItemCard>
              <Grid
                container
                direction={"column"}
                spacing={1}
                justifyContent={"center"}
                alignItems={"center"}
                sx={{ height: "188px" }}
              >
                <Grid item>
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "12px",
                    }}
                  >
                    [ 用電 ]
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      width: "61px",
                      height: "24px",
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: "30px",
                      lineHeight: "24px",
                      color: "#016665",
                    }}
                  >
                    69.2
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "12px",
                    }}
                  >
                    度
                  </Typography>
                </Grid>
                <Grid item>
                  <Divider
                    sx={{ border: "1px solid #00B388", width: "140px" }}
                  />
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "12px",
                    }}
                  >
                    [ 碳排量 ]
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "18px",
                      lineHeight: "24px",
                    }}
                  >
                    kgCO2e/ton
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "12px",
                    }}
                  >
                    kgCO2e/ton
                  </Typography>
                </Grid>
              </Grid>
            </ItemCard>
          </Grid>
          <Grid item>
            <Grid
              container
              alignItems={"center"}
              justifyContent={"center"}
              sx={{ position: "relative", width: "220px", mb: "10px" }}
            >
              <p>監控系統</p>
            </Grid>
            <ItemCard>
              <Grid
                container
                direction={"column"}
                spacing={1}
                justifyContent={"center"}
                alignItems={"center"}
                sx={{ height: "188px" }}
              >
                <Grid item>
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "12px",
                    }}
                  >
                    [ 用電 ]
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      width: "61px",
                      height: "24px",
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: "30px",
                      lineHeight: "24px",
                      color: "#016665",
                    }}
                  >
                    69.2
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "12px",
                    }}
                  >
                    度
                  </Typography>
                </Grid>
                <Grid item>
                  <Divider
                    sx={{ border: "1px solid #00B388", width: "140px" }}
                  />
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "12px",
                    }}
                  >
                    [ 碳排量 ]
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "18px",
                      lineHeight: "24px",
                    }}
                  >
                    kgCO2e/ton
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "12px",
                    }}
                  >
                    kgCO2e/ton
                  </Typography>
                </Grid>
              </Grid>
            </ItemCard>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

const HeaderCard = styled(Card)`
  padding: 12.4413px 18.6619px;
  gap: 19px;
  width: 280px;
  height: 160px;
  background: #ffffff;
  border-radius: 16px !important;
  box-shadow: none !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const ItemCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  padding: 16px;
  gap: 16px;
  width: 170.8px;
  height: 188px;
  background: #ffffff;
  border-radius: 8px !important;
  box-shadow: none !important;
`;

export default CarbonEmissionPage;
