import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

import * as style from "../styles";
import login_bg from "../assets/images/login_bg.png";

const Login = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        width: "100vw",
        background:
          "linear-gradient(115.02deg, #F5FFD8 -2.53%, #E0FDFF 102.64%)",
        boxShadow: "1px 0px 0px #00B388",
        minHeight: "calc(100vh - 113px)",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
      }}>
      <Box
        sx={{
          position: "absolute",
          right: "45px",
          bottom: "20px",
          transform: "scale(1.25)",
        }}>
        <img src={login_bg} alt="login background" />
      </Box>
      <Box
        sx={{
          background: "#fff",
          borderRadius: "28px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "36px",
          gap: "40px",
        }}>
        <Box>
          <Typography variant="h1" sx={style.headerTextStyle}>
            使用者登入
          </Typography>
        </Box>
        <Box>
          <TextField
            variant="standard"
            placeholder="請輸入帳號"
            label="帳號"
            focused
            sx={(theme) => ({
              width: "392px",
              "& .MuiFormLabel-root": {
                color: theme.palette.primary.dark,
              },
              "& ::placeholder": {
                color: theme.palette.primary.main,
              },
            })}
          />
        </Box>
        <Box>
          <TextField
            variant="standard"
            placeholder="請輸入密碼"
            label="密碼"
            focused
            sx={(theme) => ({
              width: "392px",
              "& .MuiFormLabel-root": {
                color: theme.palette.primary.dark,
              },
              "& ::placeholder": {
                color: theme.palette.primary.main,
              },
            })}
          />
        </Box>
        <Box>
          <Button
            sx={{
              ...style.containedButtonStyle,
            }}
            onClick={() => navigate("/")}>
            登入
          </Button>
          <Button sx={style.textButtonStyle}>忘記密碼</Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
