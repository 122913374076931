import Box from "@mui/material/Box";
import { ResponsiveChoropleth } from "@nivo/geo";
import { ResponsiveBar } from "@nivo/bar";
import { ResponsivePie } from "@nivo/pie";

import GeoChart from "../components/GeoChart";
import Neon from "../components/NeonLight";
import d from "../assets/data/world_countries.json";

const Dashboard = () => {
  const data = [
    {
      id: "AFG",
      value: 997048,
    },
    {
      id: "AGO",
      value: 787963,
    },
    {
      id: "ALB",
      value: 824341,
    },
    {
      id: "ARE",
      value: 192101,
    },
    {
      id: "ARG",
      value: 918579,
    },
    {
      id: "ARM",
      value: 817906,
    },
    {
      id: "ATA",
      value: 316440,
    },
    {
      id: "ATF",
      value: 417325,
    },
    {
      id: "AUT",
      value: 567692,
    },
    {
      id: "AZE",
      value: 59924,
    },
    {
      id: "BDI",
      value: 968603,
    },
    {
      id: "BEL",
      value: 788493,
    },
    {
      id: "BEN",
      value: 488053,
    },
    {
      id: "BFA",
      value: 786600,
    },
    {
      id: "BGD",
      value: 909862,
    },
    {
      id: "BGR",
      value: 784531,
    },
    {
      id: "BHS",
      value: 890340,
    },
    {
      id: "BIH",
      value: 522379,
    },
    {
      id: "BLR",
      value: 415641,
    },
    {
      id: "BLZ",
      value: 386552,
    },
    {
      id: "BOL",
      value: 214971,
    },
    {
      id: "BRN",
      value: 601138,
    },
    {
      id: "BTN",
      value: 941303,
    },
    {
      id: "BWA",
      value: 738546,
    },
    {
      id: "CAF",
      value: 263080,
    },
    {
      id: "CAN",
      value: 619189,
    },
    {
      id: "CHE",
      value: 120080,
    },
    {
      id: "CHL",
      value: 431900,
    },
    {
      id: "CHN",
      value: 610221,
    },
    {
      id: "CIV",
      value: 508059,
    },
    {
      id: "CMR",
      value: 203993,
    },
    {
      id: "COG",
      value: 627350,
    },
    {
      id: "COL",
      value: 234947,
    },
    {
      id: "CRI",
      value: 777707,
    },
    {
      id: "CUB",
      value: 9998,
    },
    {
      id: "-99",
      value: 707456,
    },
    {
      id: "CYP",
      value: 394680,
    },
    {
      id: "CZE",
      value: 325133,
    },
    {
      id: "DEU",
      value: 503685,
    },
    {
      id: "DJI",
      value: 408862,
    },
    {
      id: "DNK",
      value: 793986,
    },
    {
      id: "DOM",
      value: 612544,
    },
    {
      id: "DZA",
      value: 213327,
    },
    {
      id: "ECU",
      value: 299269,
    },
    {
      id: "EGY",
      value: 605487,
    },
    {
      id: "ERI",
      value: 811915,
    },
    {
      id: "ESP",
      value: 752278,
    },
    {
      id: "EST",
      value: 230057,
    },
    {
      id: "ETH",
      value: 519572,
    },
    {
      id: "FIN",
      value: 229934,
    },
    {
      id: "FJI",
      value: 258569,
    },
    {
      id: "FLK",
      value: 549151,
    },
    {
      id: "FRA",
      value: 145490,
    },
    {
      id: "GAB",
      value: 667549,
    },
    {
      id: "GBR",
      value: 881289,
    },
    {
      id: "GEO",
      value: 718540,
    },
    {
      id: "GHA",
      value: 60702,
    },
    {
      id: "GIN",
      value: 874215,
    },
    {
      id: "GMB",
      value: 835401,
    },
    {
      id: "GNB",
      value: 603865,
    },
    {
      id: "GNQ",
      value: 23930,
    },
    {
      id: "GRC",
      value: 203304,
    },
    {
      id: "GTM",
      value: 250701,
    },
    {
      id: "GUY",
      value: 544055,
    },
    {
      id: "HND",
      value: 501193,
    },
    {
      id: "HRV",
      value: 469060,
    },
    {
      id: "HTI",
      value: 544484,
    },
    {
      id: "HUN",
      value: 644238,
    },
    {
      id: "IDN",
      value: 276912,
    },
    {
      id: "IND",
      value: 717112,
    },
    {
      id: "IRL",
      value: 27550,
    },
    {
      id: "IRN",
      value: 239071,
    },
    {
      id: "IRQ",
      value: 785882,
    },
    {
      id: "ISL",
      value: 776911,
    },
    {
      id: "ISR",
      value: 110829,
    },
    {
      id: "ITA",
      value: 422263,
    },
    {
      id: "JAM",
      value: 944682,
    },
    {
      id: "JOR",
      value: 61891,
    },
    {
      id: "JPN",
      value: 370608,
    },
    {
      id: "KAZ",
      value: 389887,
    },
    {
      id: "KEN",
      value: 343995,
    },
    {
      id: "KGZ",
      value: 635944,
    },
    {
      id: "KHM",
      value: 236103,
    },
    {
      id: "OSA",
      value: 85909,
    },
    {
      id: "KWT",
      value: 441599,
    },
    {
      id: "LAO",
      value: 962763,
    },
    {
      id: "LBN",
      value: 743537,
    },
    {
      id: "LBR",
      value: 237132,
    },
    {
      id: "LBY",
      value: 806757,
    },
    {
      id: "LKA",
      value: 583407,
    },
    {
      id: "LSO",
      value: 490083,
    },
    {
      id: "LTU",
      value: 81468,
    },
    {
      id: "LUX",
      value: 336156,
    },
    {
      id: "LVA",
      value: 373019,
    },
    {
      id: "MAR",
      value: 675387,
    },
    {
      id: "MDA",
      value: 696812,
    },
    {
      id: "MDG",
      value: 944010,
    },
    {
      id: "MEX",
      value: 118844,
    },
    {
      id: "MKD",
      value: 951895,
    },
    {
      id: "MLI",
      value: 584455,
    },
    {
      id: "MMR",
      value: 881639,
    },
    {
      id: "MNE",
      value: 14644,
    },
    {
      id: "MNG",
      value: 160286,
    },
    {
      id: "MOZ",
      value: 249449,
    },
    {
      id: "MRT",
      value: 570404,
    },
    {
      id: "MWI",
      value: 357484,
    },
    {
      id: "MYS",
      value: 9915,
    },
    {
      id: "NAM",
      value: 817656,
    },
    {
      id: "NCL",
      value: 321876,
    },
    {
      id: "NER",
      value: 949888,
    },
    {
      id: "NGA",
      value: 925883,
    },
    {
      id: "NIC",
      value: 849345,
    },
    {
      id: "NLD",
      value: 827141,
    },
    {
      id: "NOR",
      value: 71813,
    },
    {
      id: "NPL",
      value: 383707,
    },
    {
      id: "NZL",
      value: 566508,
    },
    {
      id: "OMN",
      value: 233706,
    },
    {
      id: "PAK",
      value: 500868,
    },
    {
      id: "PAN",
      value: 345986,
    },
    {
      id: "PER",
      value: 461133,
    },
    {
      id: "PHL",
      value: 944202,
    },
    {
      id: "PNG",
      value: 832740,
    },
    {
      id: "POL",
      value: 142322,
    },
    {
      id: "PRI",
      value: 865070,
    },
    {
      id: "PRT",
      value: 40005,
    },
    {
      id: "PRY",
      value: 942815,
    },
    {
      id: "QAT",
      value: 986078,
    },
    {
      id: "ROU",
      value: 220707,
    },
    {
      id: "RUS",
      value: 659158,
    },
    {
      id: "RWA",
      value: 142474,
    },
    {
      id: "ESH",
      value: 861568,
    },
    {
      id: "SAU",
      value: 920692,
    },
    {
      id: "SDN",
      value: 228113,
    },
    {
      id: "SDS",
      value: 140954,
    },
    {
      id: "SEN",
      value: 429113,
    },
    {
      id: "SLB",
      value: 237981,
    },
    {
      id: "SLE",
      value: 162681,
    },
    {
      id: "SLV",
      value: 669099,
    },
    {
      id: "ABV",
      value: 881761,
    },
    {
      id: "SOM",
      value: 121333,
    },
    {
      id: "SRB",
      value: 191442,
    },
    {
      id: "SUR",
      value: 826735,
    },
    {
      id: "SVK",
      value: 621323,
    },
    {
      id: "SVN",
      value: 126384,
    },
    {
      id: "SWZ",
      value: 818125,
    },
    {
      id: "SYR",
      value: 428763,
    },
    {
      id: "TCD",
      value: 595853,
    },
    {
      id: "TGO",
      value: 53673,
    },
    {
      id: "THA",
      value: 700312,
    },
    {
      id: "TJK",
      value: 778320,
    },
    {
      id: "TKM",
      value: 85575,
    },
    {
      id: "TLS",
      value: 974744,
    },
    {
      id: "TTO",
      value: 179901,
    },
    {
      id: "TUN",
      value: 744982,
    },
    {
      id: "TUR",
      value: 63980,
    },
    {
      id: "TWN",
      value: 159444,
    },
    {
      id: "TZA",
      value: 600803,
    },
    {
      id: "UGA",
      value: 635254,
    },
    {
      id: "UKR",
      value: 804212,
    },
    {
      id: "URY",
      value: 106300,
    },
    {
      id: "USA",
      value: 331203,
    },
    {
      id: "UZB",
      value: 439247,
    },
    {
      id: "VEN",
      value: 929912,
    },
    {
      id: "VNM",
      value: 54430,
    },
    {
      id: "VUT",
      value: 351003,
    },
    {
      id: "PSE",
      value: 938962,
    },
    {
      id: "YEM",
      value: 592474,
    },
    {
      id: "ZAF",
      value: 329264,
    },
    {
      id: "ZMB",
      value: 185644,
    },
    {
      id: "ZWE",
      value: 611432,
    },
    {
      id: "KOR",
      value: 656544,
    },
  ];

  return (
    <Box
      sx={{
        background: "linear-gradient(180deg, #F7FFF8 60.35%, #D2FFD7 111.48%)",
        minHeight: "calc(100vh - 233px  - 64px - 16px)",
        width: "calc(100vw - 80px)",
        padding: "40px",
        display: "flex",
        gap: "40px",
      }}>
      <Box
        sx={{
          width: "65%",
          height: "calc(100vh - 193px)",
          position: "relative",
        }}>
        <Box
          sx={{
            position: "absolute",
            zIndex: 10,
            right: "300px",
            top: "100px",
          }}>
          <Neon size={"20"} r={"255"} g={"132"} b={"104"} />
        </Box>
        <Box
          sx={{
            position: "absolute",
            zIndex: 10,
            right: "400px",
            top: "150px",
          }}>
          <Neon size={"20"} r={"255"} g={"132"} b={"104"} />
        </Box>
        <Box
          sx={{
            position: "absolute",
            zIndex: 10,
            left: "150px",
            top: "180px",
          }}>
          <Neon size={"20"} r={"0"} g={"179"} b={"136"} />
        </Box>
        <Box
          sx={{
            position: "absolute",
            zIndex: 10,
            left: "250px",
            top: "120px",
          }}>
          <Neon size={"20"} r={"0"} g={"179"} b={"136"} />
        </Box>
        <Box
          sx={{
            position: "absolute",
            zIndex: 10,
            left: "300px",
            top: "250px",
          }}>
          <Neon size={"20"} r={"255"} g={"132"} b={"104"} />
        </Box>
        <Box
          sx={{
            position: "absolute",
            zIndex: 1,
            left: "0px",
            top: "0px",
            width: "100%",
          }}>
          <GeoChart />
        </Box>
        <ResponsiveChoropleth
          features={d.features}
          data={data}
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          colors={["#00b388", "#016665"]}
          unknownColor="#e2f1ee"
          domain={[0, 1000000]}
          label="properties.name"
          valueFormat=".2s"
          projectionScale={163}
          projectionTranslation={[0.5, 0.5]}
          projectionRotation={[0, 0, 0]}
          enableGraticule={true}
          graticuleLineColor="#dddddd"
          borderWidth={0.1}
          borderColor="#152538"
        />
      </Box>
      <Box
        sx={{
          width: "35%",
          display: "flex",
          flexDirection: "column",
          gap: "40px",
          justifyContent: "space-between",
        }}>
        <Box
          sx={{
            width: "100%",
            height: "calc(50vh - 199px)",
          }}>
          <ResponsiveBar
            data={[
              {
                type: "CO2",
                CO2: 20,
                CO2Color: "hsl(283, 70%, 50%)",
              },
              {
                type: "CH4",
                CH4: 30,
                CH4Color: "hsl(283, 70%, 50%)",
              },
              {
                type: "N2O",
                N2O: 43,
                N2OColor: "hsl(283, 70%, 50%)",
              },
              {
                type: "HFCs",
                HFCs: 59,
                HFCsColor: "hsl(283, 70%, 50%)",
              },
              {
                type: "PFCs",
                PFCs: 61,
                PFCsColor: "hsl(283, 70%, 50%)",
              },
              {
                type: "SF6",
                SF6: 83,
                SF6Color: "hsl(283, 70%, 50%)",
              },
            ]}
            keys={["CO2", "CH4", "N2O", "HFCs", "PFCs", "SF6"]}
            indexBy="type"
            margin={{ top: 0, right: 60, bottom: 60, left: 60 }}
            padding={0.3}
            valueScale={{ type: "linear" }}
            indexScale={{ type: "band", round: true }}
            colors={{ scheme: "nivo" }}
            defs={[
              {
                id: "dots",
                type: "patternDots",
                background: "inherit",
                color: "#38bcb2",
                size: 4,
                padding: 1,
                stagger: true,
              },
              {
                id: "lines",
                type: "patternLines",
                background: "inherit",
                color: "#eed312",
                rotation: -45,
                lineWidth: 6,
                spacing: 10,
              },
            ]}
            fill={[
              {
                match: {
                  id: "fries",
                },
                id: "dots",
              },
              {
                match: {
                  id: "sandwich",
                },
                id: "lines",
              },
            ]}
            borderColor={{
              from: "color",
              modifiers: [["darker", 1.6]],
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={null}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Co2e(t)",
              legendPosition: "middle",
              legendOffset: -40,
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{
              from: "color",
              modifiers: [["darker", 1.6]],
            }}
            legends={[
              {
                dataFrom: "keys",
                anchor: "bottom",
                direction: "row",
                justify: true,
                translateX: 0,
                translateY: 30,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: "left-to-right",
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
            role="application"
            ariaLabel="Nivo bar chart demo"
            barAriaLabel={(e) =>
              e.id + ": " + e.formattedValue + " in country: " + e.indexValue
            }
          />
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "calc(50vh - 199px)",
          }}>
          <ResponsivePie
            data={[
              {
                id: "CO2",
                label: "CO2",
                value: 20,
                color: "hsl(283, 70%, 50%)",
              },
              {
                id: "CH4",
                label: "CH4",
                value: 30,
                color: "hsl(283, 70%, 50%)",
              },
              {
                id: "N2O",
                label: "N2O",
                value: 43,
                color: "hsl(283, 70%, 50%)",
              },
              {
                id: "HFCs",
                label: "HFCs",
                value: 59,
                color: "hsl(283, 70%, 50%)",
              },
              {
                id: "PFCs",
                label: "PFCs",
                value: 61,
                color: "hsl(283, 70%, 50%)",
              },
              {
                id: "SF6",
                label: "SF6",
                value: 83,
                color: "hsl(283, 70%, 50%)",
              },
            ]}
            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            borderColor={{
              from: "color",
              modifiers: [["darker", 0.2]],
            }}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="#333333"
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: "color" }}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{
              from: "color",
              modifiers: [["darker", 2]],
            }}
            defs={[
              {
                id: "dots",
                type: "patternDots",
                background: "inherit",
                color: "rgba(255, 255, 255, 0.3)",
                size: 4,
                padding: 1,
                stagger: true,
              },
              {
                id: "lines",
                type: "patternLines",
                background: "inherit",
                color: "rgba(255, 255, 255, 0.3)",
                rotation: -45,
                lineWidth: 6,
                spacing: 10,
              },
            ]}
            fill={[
              {
                match: {
                  id: "ruby",
                },
                id: "dots",
              },
              {
                match: {
                  id: "c",
                },
                id: "dots",
              },
              {
                match: {
                  id: "go",
                },
                id: "dots",
              },
              {
                match: {
                  id: "python",
                },
                id: "dots",
              },
              {
                match: {
                  id: "scala",
                },
                id: "lines",
              },
              {
                match: {
                  id: "lisp",
                },
                id: "lines",
              },
              {
                match: {
                  id: "elixir",
                },
                id: "lines",
              },
              {
                match: {
                  id: "javascript",
                },
                id: "lines",
              },
            ]}
            legends={[
              {
                anchor: "bottom",
                direction: "row",
                justify: false,
                translateX: 0,
                translateY: 56,
                itemsSpacing: 0,
                itemWidth: 100,
                itemHeight: 18,
                itemTextColor: "#999",
                itemDirection: "left-to-right",
                itemOpacity: 1,
                symbolSize: 18,
                symbolShape: "circle",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemTextColor: "#000",
                    },
                  },
                ],
              },
            ]}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
