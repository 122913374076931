import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import * as style from "../../styles";
import example_photo_upload from "../../assets/images/example_photo_upload.png";

const ProductInfo = () => {
  return (
    <Box sx={{ p: "40px", minHeight: "calc(100vh - 193px)" }}>
      <Grid container sx={{ gap: "32px" }}>
        <Grid
          item
          xs={12}
          sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <Typography variant="h1" sx={style.headerTextStyle}>
            產品/服務基本資料
          </Typography>
          <Typography
            sx={(theme) => ({
              ">span": {
                color: theme.palette.error.main,
              },
            })}>
            請填寫以下欄位，
            <span>儲存後將綁定專案與產品/服務且無法再進行修改。</span>
          </Typography>
        </Grid>
        <Grid item>
          <Box
            sx={{ display: "flex", gap: "36px", width: "calc(100vw - 80px)" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "16px",
              }}>
              <Box sx={{ width: "100%" }}>
                <Typography
                  variant="h6"
                  sx={(theme) => ({
                    fontFamily: "Noto Sans TC",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "33px",
                    letterSpacing: "0.15px",
                    color: theme.palette.primary.dark,
                  })}>
                  產品/服務照片
                </Typography>
              </Box>
              <Box>
                <img src={example_photo_upload} alt="example_photo_upload" />
              </Box>
              <Box>
                <Button
                  sx={{
                    ...style.containedButtonStyle,
                    background:
                      "linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%), #FBBB0A;",
                  }}>
                  重新上傳照片
                </Button>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                width: "100%",
              }}>
              <Box>
                <Typography
                  variant="h6"
                  sx={(theme) => ({
                    fontFamily: "Noto Sans TC",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "33px",
                    letterSpacing: "0.15px",
                    color: theme.palette.primary.dark,
                  })}>
                  產品/服務基本資料
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "stretch",
                  gap: "16px",
                }}>
                <TextField
                  size="small"
                  focused
                  label="產品/服務名稱(中文)"
                  fullWidth
                  sx={{ width: "50%", background: "#F5F5F5" }}
                />
                <TextField
                  size="small"
                  focused
                  label="產品/服務名稱(英文)"
                  fullWidth
                  sx={{ width: "50%", background: "#F5F5F5" }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "stretch",
                  gap: "16px",
                }}>
                <TextField
                  size="small"
                  focused
                  label="產品/服務編號"
                  fullWidth
                  sx={{ width: "50%", background: "#F5F5F5" }}
                />
                <TextField
                  size="small"
                  focused
                  label="盤查期間 - 起"
                  fullWidth
                  sx={{ width: "50%", background: "#F5F5F5" }}
                />
                <TextField
                  size="small"
                  focused
                  label="盤查期間 - 訖"
                  fullWidth
                  sx={{ width: "50%", background: "#F5F5F5" }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "stretch",
                  gap: "16px",
                }}>
                <TextField
                  size="small"
                  focused
                  label="產品/服務通用計量單位或功能單位 (個、式)"
                  fullWidth
                  sx={{ width: "50%", background: "#F5F5F5" }}
                />
                <TextField
                  size="small"
                  focused
                  label="產品/服務在盤查期間內總量"
                  fullWidth
                  sx={{ width: "50%", background: "#F5F5F5" }}
                />
                <TextField
                  size="small"
                  focused
                  label="產品/服務總量"
                  fullWidth
                  sx={{ width: "50%", background: "#F5F5F5" }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "stretch",
                  gap: "16px",
                }}>
                <TextField
                  size="small"
                  focused
                  label="盤查期間「本產品/服務總量」佔「產品/服務總量」(%)"
                  fullWidth
                  sx={{ width: "100%", background: "#F5F5F5" }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "stretch",
                  gap: "16px",
                }}>
                <TextField
                  size="small"
                  focused
                  label="上述比例之計算分配原則說明"
                  fullWidth
                  sx={{ width: "100%", background: "#F5F5F5" }}
                />
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item>
          <Box
            sx={{
              width: "calc(100vw - 80px)",
              display: "flex",
              justifyContent: "center",
            }}>
            <Button sx={style.containedButtonStyle}>儲存</Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProductInfo;
