import { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

import { NavLink as RouterLink, useMatch } from "react-router-dom";

export default function SideDrawer() {
  const [openKey, setOpenKey] = useState("");
  const [open, setOpen] = useState(false);
  const [height, setHeight] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  const isCarbEmissionProject = useMatch("/carb-emission/*");
  const isCardFootprintProject = useMatch("/carb-footprint/*");
  // const isEnergyManagementProject = useMatch("/energy-management/*");

  const carb_emission_list = [
    {
      name: "基本鑑別資料",
      key: "basic_identification",
      subOptions: [
        {
          name: "鑑別方法",
          key: "identification_method",
          url: "/carb-emission/identification-method",
          disabled: false,
        },
        {
          name: "量表定義",
          key: "scale_definition",
          url: "/carb-emission/scale-definition",
          disabled: false,
        },
        {
          name: "策略表格",
          key: "strategy_form",
          url: "/carb-emission/strategy-form",
          disabled: false,
        },
      ],
    },
    {
      name: "類別一",
      key: "category_one",
      subOptions: [
        {
          name: "公務車（柴油）",
          key: "business-car-gas",
          url: "/carb-emission/business-car-gas",
          disabled: false,
        },
        {
          name: "臨時機具（汽柴油）",
          key: "transportation",
          url: "/transportation",
          disabled: true,
        },
        {
          name: "滅火器",
          key: "employee",
          url: "/employee",
          disabled: true,
        },
        {
          name: "工作時數（員工）",
          key: "employee",
          url: "/employee",
          disabled: true,
        },
        {
          name: "工作時數（非員工）",
          key: "employee",
          url: "/employee",
          disabled: true,
        },
        {
          name: "冷媒",
          key: "refrigerant",
          url: "/carb-emission/refrigerant",
          disabled: false,
        },
        {
          name: "廠內機具",
          key: "employee",
          url: "/employee",
          disabled: true,
        },
        {
          name: "緊急發電機",
          key: "employee",
          url: "/employee",
          disabled: true,
        },
        {
          name: "其他",
          key: "employee",
          url: "/employee",
          disabled: true,
        },
      ],
    },
    {
      name: "類別二",
      key: "category_two",
      subOptions: [],
    },
    {
      name: "類別三",
      key: "category_three",
      subOptions: [],
    },
  ];

  const carb_footprint_list = [
    {
      name: "產品服務基本資料",
      key: "product_profile",
      url: "/carb-footprint/product-info",
    },
    {
      name: "原物料階段",
      key: "material_phase",
      url: "/carb-footprint/material-phase",
    },
    {
      name: "製造階段",
      key: "manufacturing_phase",
      url: "/carb-footprint/manufacturing-phase",
    },
    {
      name: "廢棄物階段",
      key: "wasted_phase",
      url: "/carb-footprint/wasted-phase",
    },
    {
      name: "其他",
      key: "other",
      url: "/carb-footprint/other",
    },
  ];

  const handleToggleMenu = (key: string) => {
    setOpenKey(key === openKey ? "" : key);
  };

  const handleToggleMainMenu = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (ref?.current?.clientHeight) {
      const height = ref.current.clientHeight;
      setHeight(height);
    }
  }, [ref?.current?.clientHeight]);

  useEffect(() => {
    setOpen(false);
  }, []);

  if (!isCarbEmissionProject && !isCardFootprintProject)
    return <Box sx={{ width: "0px" }}></Box>;

  return (
    <Box
      sx={{
        background: "linear-gradient(180deg, #016665 0%, #0AA5A3 100%)",
        boxShadow: "1px 0px 0px #00B388",
        backgroundColor: "#016665",
        p: "20px",
        position: "absolute",
        zIndex: 20,
        right: "50px",
        top: open ? `113px` : `${-height + 173}px`,
        borderRadius: "0px 0px 15px 15px",
        transition: "top 0.5s",
        width: "180px",
      }}
      ref={ref}>
      <List component="nav" sx={{ color: "white" }}>
        {isCarbEmissionProject &&
          carb_emission_list.map((primary_menu) => {
            return (
              <>
                <ListItem
                  sx={{
                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                    borderLeft: "4px solid #ffff",
                    my: "8px",
                  }}
                  key={primary_menu.key}
                  disablePadding>
                  <ListItemButton
                    onClick={() => handleToggleMenu(primary_menu.key)}>
                    <ListItemText primary={primary_menu.name} />
                    {primary_menu.key === openKey ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                  </ListItemButton>
                </ListItem>
                <Collapse
                  in={primary_menu.key === openKey}
                  timeout="auto"
                  unmountOnExit>
                  <List
                    sx={{
                      ".active": {
                        background:
                          "linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%), #FBBB0A",
                        borderRadius: "8px",
                        p: "8px 16px",
                        transform: "translateX(-16px)",
                        color: "#000000",
                        fontWeight: "bold",
                      },
                    }}
                    component="div"
                    disablePadding>
                    {primary_menu.subOptions.map((sub_menu) => (
                      <ListItem key={sub_menu.key} sx={{ pl: 4 }}>
                        {sub_menu.disabled ? (
                          <Typography
                            sx={{
                              fontStyle: "normal",
                              fontWeight: "normal",
                              fontSize: "16px",
                              lineHeight: "24px",
                              color: "#a5a5a5",
                              textDecoration: "none",
                            }}>
                            {sub_menu.name}
                          </Typography>
                        ) : (
                          <Link
                            component={RouterLink}
                            to={sub_menu.url}
                            sx={{
                              fontStyle: "normal",
                              fontWeight: "normal",
                              fontSize: "16px",
                              lineHeight: "24px",
                              color: "#FFFFFF",
                              textDecoration: "none",
                            }}>
                            {sub_menu.name}
                          </Link>
                        )}
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              </>
            );
          })}
        {isCardFootprintProject &&
          carb_footprint_list.map((menu) => {
            return (
              <>
                <List
                  sx={{
                    ".active": {
                      background:
                        "linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%), #FBBB0A",
                      borderRadius: "8px",
                      p: "8px 16px",
                      transform: "translateX(-16px)",
                      color: "#000000",
                      fontWeight: "bold",
                    },
                  }}
                  component="div"
                  disablePadding>
                  <ListItem key={menu.key} sx={{ pl: 4 }}>
                    <Link
                      component={RouterLink}
                      to={menu.url}
                      sx={{
                        fontStyle: "normal",
                        fontWeight: "normal",
                        fontSize: "16px",
                        lineHeight: "24px",
                        color: "#FFFFFF",
                        textDecoration: "none",
                      }}>
                      {menu.name}
                    </Link>
                  </ListItem>
                </List>
              </>
            );
          })}
      </List>
      <Box
        onClick={handleToggleMainMenu}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}>
        <Box sx={{ color: "#fff" }}>
          {open ? <ExpandLess /> : <ExpandMore />}
        </Box>
        <Typography sx={{ color: "#fff" }}>
          {open ? "收合選單列" : "展開選單列"}
        </Typography>
      </Box>
    </Box>
  );
}
