import styled, { keyframes } from "styled-components";

const NeonLight = (props: {
  size: string;
  r: string;
  g: string;
  b: string;
}) => {
  const { size, r, g, b } = props;

  return <StyledNeonLight size={size} r={r} g={g} b={b} />;
};

const animGlow = (size: string, r: string, g: string, b: string) => keyframes`
    0% {
      box-shadow: 0 0 0 ${(+size - 10).toString()}px rgba(${r},${g},${b},0);
    }
    10% {
      box-shadow: 0 0 ${(+size + 1).toString()}px ${(
  +size - 9
).toString()}px rgba(${r},${g},${b},0.1);
    }
    20% {
      box-shadow: 0 0 ${(+size + 2).toString()}px ${(
  +size - 8
).toString()}px rgba(${r},${g},${b},0.2);
    }
    30% {
      box-shadow: 0 0 ${(+size + 3).toString()}px ${(
  +size - 7
).toString()}px rgba(${r},${g},${b},0.3);
    }
    40% {
      box-shadow: 0 0 ${(+size + 4).toString()}px ${(
  +size - 6
).toString()}px rgba(${r},${g},${b},0.4);
    }
    50% {
      box-shadow: 0 0 ${(+size + 5).toString()}px ${(
  +size - 5
).toString()}px rgba(${r},${g},${b},0.5);
    }
    60% {
      box-shadow: 0 0 ${(+size + 6).toString()}px ${(
  +size - 4
).toString()}px rgba(${r},${g},${b},0.6);
    }
    70% {
      box-shadow: 0 0 ${(+size + 7).toString()}px ${(
  +size - 3
).toString()}px rgba(${r},${g},${b},0.7);
    }
    80% {
      box-shadow: 0 0 ${(+size + 8).toString()}px ${(
  +size - 2
).toString()}px rgba(${r},${g},${b},0.8);
    }
    90% {
      box-shadow: 0 0 ${(+size + 9).toString()}px ${(
  +size - 1
).toString()}px rgba(${r},${g},${b},0.9);
    }
    100% {
      box-shadow: 0 0 ${(
        +size + 10
      ).toString()}px ${size}px rgba(${r},${g},${b},1);; 
    }
`;

const StyledNeonLight = styled.div<{
  size: string;
  r: string;
  g: string;
  b: string;
}>`
  width: ${(p) => p.size}px;
  height: ${(p) => p.size}px;
  border-radius: 50%;
  animation: ${(p) => animGlow(p.size, p.r, p.g, p.b)} 2s ease infinite;
  background-color: #fff;
  background: ${(p) => `rgb(${p.r}, ${p.g}, ${p.b})`};
  background: radial-gradient(
    circle,
    ${(p) => `rgba(${p.r}, ${p.g}, ${p.b}, 100) 0%`},
    ${(p) => `rgba(${p.r}, ${p.g}, ${p.b}, 50) 50%`},
    ${(p) => `rgba(${p.r}, ${p.g}, ${p.b}, 0) 90%`},
    rgba(255, 255, 255, 1)
  );
`;

export default NeonLight;
