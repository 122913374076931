import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

import * as style from "../styles";
import gas_emission from "../assets/images/gas_emission.png";
import carb_footprint from "../assets/images/carb_footprint.png";
import energy_management from "../assets/images/energy_management.png";

const Home = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        background: "linear-gradient(180deg, #016665 0%, #0AA5A3 100%)",
        width: "calc(100vw - 80px)",
        minHeight: "calc(100vh - 193px)",
        padding: "40px",
      }}>
      <Grid container gap={"32px"} flexDirection={"column"}>
        <Grid item>
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}>
            <Grid item>
              <Typography
                variant="h2"
                sx={{ ...style.headerTextStyle, color: "#ffffff" }}>
                入口功能頁
              </Typography>
            </Grid>
            <Grid item>
              <Button sx={{ ...style.textButtonStyle, color: "#FBBB0A" }}>
                授權管理設定
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container justifyContent={"space-between"}>
            <Grid
              item
              xs={3.5}
              sx={{
                background: "#ffffff",
                borderRadius: "15px",
                padding: "80px 36px",
                height: "calc(100vh - 258px)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "24px",
                  alignItems: "center",
                }}>
                <Typography variant="h2" sx={style.headerTextStyle}>
                  溫室氣體盤查整合
                </Typography>
                <Typography sx={style.textStyle}>
                  依 ISO 14064
                  標準，確認標的與邊界、鑑別來源與收集後，計算碳排放量並產出碳排放報告書。
                </Typography>
                <Button
                  onClick={() => navigate("/carbon-emission-project-list")}
                  sx={style.containedButtonStyle}>
                  進入功能
                </Button>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-end",
                }}>
                <img
                  style={{ width: "80%" }}
                  src={gas_emission}
                  alt="gas_emission"
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={3.5}
              sx={{
                background: "#ffffff",
                borderRadius: "15px",
                padding: "80px 36px",
                height: "calc(100vh - 258px)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "24px",
                  alignItems: "center",
                }}>
                <Typography variant="h2" sx={style.headerTextStyle}>
                  資訊服務碳足跡
                </Typography>
                <Typography sx={style.textStyle}>
                  依 ISO 14067
                  標準，確認標的與邊界、盤查與收集資料後，計算服務碳足跡並產出碳足跡報告書。
                </Typography>
                <Button
                  onClick={() => navigate("/carbon-footprint-project-list")}
                  sx={style.containedButtonStyle}>
                  進入功能
                </Button>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-end",
                }}>
                <img
                  style={{ width: "80%" }}
                  src={carb_footprint}
                  alt="carb_footprint"
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={3.5}
              sx={{
                background: "#ffffff",
                borderRadius: "15px",
                padding: "80px 36px",
                height: "calc(100vh - 258px)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "24px",
                  alignItems: "center",
                }}>
                <Typography variant="h2" sx={style.headerTextStyle}>
                  能源管理
                </Typography>
                <Typography sx={style.textStyle}>
                  依 ISO 50001
                  標準，制定能源政策與目標，持續實施與運作、監督與量測並進行矯正與預防，持續改善後達成目標。放量並產出碳排放報告書。
                </Typography>
                <Button
                  onClick={() => navigate("/energy-management-project-list")}
                  sx={style.containedButtonStyle}>
                  進入功能
                </Button>
                <Button sx={style.textButtonStyle}>
                  尚未開通本服務，請點此聯繫客服。
                </Button>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-end",
                }}>
                <img
                  style={{ width: "80%" }}
                  src={energy_management}
                  alt="energy_management"
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Home;
