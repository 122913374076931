import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";

import GeoChart from "../components/GeoChart";
import LineChart from "../components/LineChart";
import BarChart from "../components/BarChart";
// import NetworkChart from "../components/NetworkChart";
import Neon from "../components/NeonLight";
import * as style from "../styles";
// import prod from "../assets/images/product.png";
import carb_flow from "../assets/images/carb_footprint_flow.png";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface LineChartData {
  id: string;
  data: { x: string; y: number }[] | [];
  color?: string;
}

interface LineChartDataProps extends Array<LineChartData> {}

const Dashboard = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [lineData, setLineData] = useState<LineChartDataProps | []>();
  const [lineChartYaxis, setLineChartYaxis] = useState<string>("");

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // function randomIntFromInterval(min: number, max: number) {
  //   return Math.floor(Math.random() * (max - min + 1) + min);
  // }

  useEffect(() => {
    const date = new Date();
    let minutes = date.getMinutes();
    let hours = date.getHours();
    let seconds = date.getSeconds();
    let month = date.getMonth() + 1;
    const data: { x: string; y: number }[] = [];
    const prevData: { x: string; y: number }[] = [];
    const currentData: { x: string; y: number }[] = [];

    switch (value) {
      case 0:
        for (let i = 0; i < 5; i++) {
          const a = [3.8, 2.1, 3.9, 4.1, 3.6];
          data.push({
            x: `${hours.toString()}:${(
              minutes +
              i * 5
            ).toString()}:${seconds.toString()}`,
            y: a[i],
          });
        }
        setLineData([
          {
            id: "用電監控",
            data: data,
            color: "rgba(0,0,0,0.5)",
          },
        ]);
        setLineChartYaxis("kWh");
        break;
      case 1:
        const b = [2.28, 3.16, 3.9, 4.1, 5.2];

        for (let i = 0; i < 5; i++) {
          data.push({
            x: `${hours}:${minutes + i * 5}:${seconds}`,
            y: b[i],
          });
        }
        setLineData([
          {
            id: "用電累計",
            data: data,
          },
        ]);
        setLineChartYaxis("kWh");
        break;
      case 2:
        for (let i = 0; i < month; i++) {
          const c = [
            2.28, 3.16, 3.9, 4.1, 5.2, 6.0, 7.12, 8.11, 8.9, 9.15, 11.44, 11.9,
          ];
          const d = [
            2.1, 3.75, 4.5, 4.9, 6.0, 6.5, 7.11, 7.95, 8.15, 10.44, 10.9,
          ];

          prevData.push({
            x: `${i + 1}月`,
            y: c[i],
          });
          currentData.push({
            x: `${i + 1}月`,
            y: d[i],
          });
        }
        setLineData([
          {
            id: "去年用電",
            data: prevData,
          },
          {
            id: "今年用電",
            data: currentData,
          },
        ]);
        setLineChartYaxis("kWh");

        break;
      case 3:
        const e = [18.28, 21.16, 17.9, 10.1, 15.2];

        for (let i = 0; i < 5; i++) {
          data.push({
            x: `${hours.toString()}:${(
              minutes +
              i * 5
            ).toString()}:${seconds.toString()}`,
            y: e[i],
          });
        }
        setLineData([
          {
            id: "CO2e監控",
            data: data,
          },
        ]);
        setLineChartYaxis("Co2e(kg)");

        break;
      case 4:
        const f = [4.28, 5.16, 7.9, 8.1, 8.97];

        for (let i = 0; i < 5; i++) {
          data.push({
            x: `${hours}:${minutes + i * 5}:${seconds}`,
            y: f[i],
          });
        }
        setLineData([
          {
            id: "CO2e累計",
            data: data,
          },
        ]);
        setLineChartYaxis("Co2e(t)");

        break;
      case 5:
        for (let i = 0; i < month; i++) {
          const g = [
            5.28, 6.16, 7.9, 8.5, 8.97, 9.12, 10.11, 11.9, 12.15, 13.44, 14.9,
            15.5,
          ];
          const h = [
            4.28, 5.16, 6.9, 8.1, 8.5, 9.12, 10.11, 11.9, 12.15, 13.44, 14.9,
            15.5,
          ];

          prevData.push({
            x: `${i + 1}月`,
            y: g[i],
          });
          currentData.push({
            x: `${i + 1}月`,
            y: h[i],
          });
        }
        setLineData([
          {
            id: "去年CO2e用量",
            data: prevData,
          },
          {
            id: "今年CO2e用量",
            data: currentData,
          },
        ]);
        setLineChartYaxis("Co2e(t)");

        break;
      default:
        setLineData([
          {
            id: "",
            data: [],
          },
        ]);
    }
  }, [value]);

  return (
    <Box
      sx={{
        background: "linear-gradient(180deg, #016665 0%, #0AA5A3 100%)",
        minHeight: "calc(100vh - 233px  - 64px - 16px)",
        width: "calc(100vw - 80px)",
        padding: "40px",
        display: "flex",
        flexDirection: "column",
        gap: "40px",
      }}>
      <Box
        sx={{
          display: "flex",
          gap: "40px",
        }}>
        <Box
          sx={{
            background: "#ffffff",
            borderRadius: "15px",
            width: "30%",
            height: "calc((100vh - 233px - 64px - 16px)*0.5)",
            padding: "32px",
            gap: "16px",
            display: "flex",
            flexDirection: "column",
          }}>
          <Box>
            <Typography
              variant="h6"
              sx={(theme) => ({ color: theme.palette.primary.main })}>
              組織低碳能源監控
            </Typography>
          </Box>
          <Box
            sx={{
              position: "relative",
            }}>
            <Box
              sx={{
                position: "absolute",
                zIndex: 15,
                right: "0px",
                top: "calc((100vh - 493px)*0.5)",
              }}>
              <Button
                sx={{
                  ...style.containedButtonStyle,
                }}
                onClick={() => navigate("/demo-dashboard-geographic")}>
                更多資訊
              </Button>
            </Box>
            <Box
              sx={{
                position: "absolute",
                zIndex: 10,
                right: "10px",
                top: "100px",
              }}>
              <Neon size={"5"} r={"255"} g={"132"} b={"104"} />
            </Box>
            <Box
              sx={{
                position: "absolute",
                zIndex: 10,
                right: "40px",
                top: "80px",
              }}>
              <Neon size={"5"} r={"255"} g={"132"} b={"104"} />
            </Box>
            <Box
              sx={{
                position: "absolute",
                zIndex: 10,
                left: "50px",
                top: "180px",
              }}>
              <Neon size={"5"} r={"0"} g={"179"} b={"136"} />
            </Box>
            <Box
              sx={{
                position: "absolute",
                zIndex: 10,
                left: "250px",
                top: "120px",
              }}>
              <Neon size={"5"} r={"0"} g={"179"} b={"136"} />
            </Box>
            <Box
              sx={{
                position: "absolute",
                zIndex: 10,
                left: "300px",
                top: "250px",
              }}>
              <Neon size={"5"} r={"255"} g={"132"} b={"104"} />
            </Box>
            <Box
              sx={{
                position: "absolute",
                zIndex: 1,
                left: "0px",
                top: "0px",
                width: "100%",
              }}>
              <GeoChart />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            background: "#ffffff",
            borderRadius: "15px",
            width: "70%",
            height: "calc((100vh - 233px - 64px - 16px)*0.5)",
            padding: "32px",
          }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example">
            <Tab label="用電監控" {...a11yProps(0)} />
            <Tab label="用電累計" {...a11yProps(1)} />
            <Tab label="用電比較" {...a11yProps(2)} />
            <Tab
              label="CO2e監控"
              {...a11yProps(2)}
              sx={{
                textTransform: "inherit",
              }}
            />
            <Tab
              label="CO2e累計"
              {...a11yProps(2)}
              sx={{
                textTransform: "inherit",
              }}
            />
            <Tab
              label="CO2e比較"
              {...a11yProps(2)}
              sx={{
                textTransform: "inherit",
              }}
            />
          </Tabs>
          <LineChart data={lineData} y={lineChartYaxis} />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "40px",
        }}>
        <Box
          sx={{
            background: "#ffffff",
            borderRadius: "15px",
            width: "30%",
            height: "calc((100vh - 233px - 64px - 16px)*0.5)",
            padding: "32px",
            gap: "16px",
            display: "flex",
            flexDirection: "column",
          }}>
          <Box>
            <Typography
              variant="h6"
              sx={(theme) => ({ color: theme.palette.primary.main })}>
              資訊服務碳足跡
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="h6"
              sx={(theme) => ({
                color: theme.palette.primary.dark,
                fontSize: "12px",
                fontWeight: 400,
              })}>
              單位產品碳排放量
            </Typography>
            <Typography
              sx={(theme) => ({
                fontSize: "32px",
                fontWeight: 700,
                ">span": {
                  fontSize: "12px",
                },
              })}>
              23.8 <span>kg /CO2e</span>
            </Typography>
          </Box>
          <Box>
            <img src={carb_flow} alt="carb_flow" style={{ width: "100%" }} />
          </Box>
          {/* <Box sx={{ position: "relative" }}>
            <Box
              sx={{
                position: "absolute",
                zIndex: 15,
                right: "0px",
                top: "calc((100vh - 493px)*0.5)",
              }}>
              <Button
                sx={{
                  ...style.containedButtonStyle,
                }}
                onClick={() => navigate("/demo-dashboard-network")}>
                更多資訊
              </Button>
            </Box>
            <Box>
              <Typography
                variant="h6"
                sx={(theme) => ({
                  color: theme.palette.primary.dark,
                  fontSize: "12px",
                  fontWeight: 400,
                })}>
                單位產品碳排放量
              </Typography>
              <Typography
                sx={(theme) => ({
                  fontSize: "32px",
                  fontWeight: 700,
                  ">span": {
                    fontSize: "12px",
                  },
                })}>
                23.8 <span>kg /CO2e</span>
              </Typography>
            </Box>
            <Box
              sx={{
                position: "absolute",
                width: "50%",
                right: "20px",
                top: "-100px",

                zIndex: 2,
              }}>
              <img src={prod} alt="prod" style={{ width: "100%" }} />
            </Box>
            <Box
              sx={(theme) => ({
                position: "absolute",
                width: "60%",
                right: "1px",
                borderRadius: "60px",
                top: "-10px",
                height: "100px",
                background: theme.palette.primary.main,
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              })}></Box>
          </Box>
          <NetworkChart /> */}
        </Box>
        <Box
          sx={{
            background: "#ffffff",
            borderRadius: "15px",
            width: "45%",
            height: "calc((100vh - 233px - 64px - 16px)*0.5)",
            padding: "32px",
            gap: "16px",
            display: "flex",
            flexDirection: "column",
          }}>
          <Box>
            <Typography
              variant="h6"
              sx={(theme) => ({ color: theme.palette.primary.main })}>
              溫室氣體排放分析
            </Typography>
          </Box>
          <BarChart />
        </Box>
        <Box
          sx={{
            background: "#ffffff",
            borderRadius: "15px",
            width: "25%",
            height: "calc((100vh - 233px - 64px - 16px)*0.5)",
            padding: "32px",
            gap: "16px",
            display: "flex",
            flexDirection: "column",
          }}>
          <Box>
            <Typography
              variant="h6"
              sx={(theme) => ({ color: theme.palette.primary.main })}>
              環境監測資訊
            </Typography>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow
                    sx={{
                      ">td": {
                        padding: "1vh 1.2vw",
                      },
                    }}>
                    <TableCell
                      sx={{
                        ...style.textStyle,
                        fontWeight: "bold",
                      }}>
                      溫度
                    </TableCell>
                    <TableCell>25.8 °C</TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      ">td": {
                        padding: "1vh 1.2vw",
                      },
                    }}>
                    <TableCell
                      sx={{
                        ...style.textStyle,
                        fontWeight: "bold",
                      }}>
                      相對濕度
                    </TableCell>
                    <TableCell>70 %</TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      ">td": {
                        padding: "1vh 1.2vw",
                      },
                    }}>
                    <TableCell
                      sx={{
                        ...style.textStyle,
                        fontWeight: "bold",
                      }}>
                      細懸浮微粒
                    </TableCell>
                    <TableCell>59 ug/m^2</TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      ">td": {
                        padding: "1vh 1.2vw",
                      },
                    }}>
                    <TableCell
                      sx={{
                        ...style.textStyle,
                        fontWeight: "bold",
                      }}>
                      平均風速
                    </TableCell>
                    <TableCell>23 m/m^3</TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      ">td": {
                        padding: "1vh 1.2vw",
                      },
                    }}>
                    <TableCell
                      sx={{
                        ...style.textStyle,
                        fontWeight: "bold",
                      }}>
                      紫外線指數
                    </TableCell>
                    <TableCell>4</TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      ">td": {
                        padding: "1vh 1.2vw",
                      },
                    }}>
                    <TableCell
                      sx={{
                        ...style.textStyle,
                        fontWeight: "bold",
                      }}>
                      環境噪音量
                    </TableCell>
                    <TableCell>36 dB</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
