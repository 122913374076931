import Box from "@mui/material/Box";
import { ResponsiveBar } from "@nivo/bar";

const BarChart = () => {
  // function randomIntFromInterval(min: number, max: number) {
  //   return Math.floor(Math.random() * (max - min + 1) + min);
  // }

  const data = [
    {
      country: "2022",
      CO2: 3,
      CO2Color: "hsl(159, 70%, 50%)",
      CH4: 4,
      CH4Color: "hsl(202, 70%, 50%)",
      N2O: 11,
      N2OColor: "hsl(102, 70%, 50%)",
      HFCs: 3,
      HFCsColor: "hsl(73, 70%, 50%)",
      PFCs: 16,
      PFCsColor: "hsl(88, 70%, 50%)",
      SF6: 8,
      SF6Color: "hsl(283, 70%, 50%)",
    },
    {
      country: "2023",
      CO2: 10,
      CO2Color: "hsl(159, 70%, 50%)",
      CH4: 15,
      CH4Color: "hsl(202, 70%, 50%)",
      N2O: 5,
      N2OColor: "hsl(102, 70%, 50%)",
      HFCs: 9,
      HFCsColor: "hsl(73, 70%, 50%)",
      PFCs: 18,
      PFCsColor: "hsl(88, 70%, 50%)",
      SF6: 19,
      SF6Color: "hsl(283, 70%, 50%)",
    },
    {
      country: "2021",
      CO2: 5,
      CO2Color: "hsl(159, 70%, 50%)",
      CH4: 4,
      CH4Color: "hsl(202, 70%, 50%)",
      N2O: 7,
      N2OColor: "hsl(102, 70%, 50%)",
      HFCs: 12,
      HFCsColor: "hsl(73, 70%, 50%)",
      PFCs: 18,
      PFCsColor: "hsl(88, 70%, 50%)",
      SF6: 18,
      SF6Color: "hsl(283, 70%, 50%)",
    },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        height: "calc((100vh - 393px)*0.5)",
      }}>
      <ResponsiveBar
        data={data}
        keys={["CO2", "CH4", "N2O", "HFCs", "PFCs", "SF6"]}
        indexBy="country"
        margin={{ top: 0, right: 60, bottom: 60, left: 60 }}
        padding={0.3}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        colors={{ scheme: "nivo" }}
        defs={[
          {
            id: "dots",
            type: "patternDots",
            background: "inherit",
            color: "#38bcb2",
            size: 4,
            padding: 1,
            stagger: true,
          },
          {
            id: "lines",
            type: "patternLines",
            background: "inherit",
            color: "#eed312",
            rotation: -45,
            lineWidth: 6,
            spacing: 10,
          },
        ]}
        fill={[
          {
            match: {
              id: "fries",
            },
            id: "dots",
          },
          {
            match: {
              id: "sandwich",
            },
            id: "lines",
          },
        ]}
        borderColor={{
          from: "color",
          modifiers: [["darker", 1.6]],
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={null}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Co2e(t)",
          legendPosition: "middle",
          legendOffset: -40,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
          from: "color",
          modifiers: [["darker", 1.6]],
        }}
        legends={[
          {
            dataFrom: "keys",
            anchor: "bottom",
            direction: "row",
            justify: true,
            translateX: 0,
            translateY: 30,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: "left-to-right",
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        role="application"
        ariaLabel="Nivo bar chart demo"
        barAriaLabel={(e) =>
          e.id + ": " + e.formattedValue + " in country: " + e.indexValue
        }
      />
    </Box>
  );
};

export default BarChart;
