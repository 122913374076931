export const headerTextStyle = {
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "28px",
  lineHeight: "33px",
  color: "#016665",
};

export const textStyle = {
  fontFamily: "'Inter'",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "28px",
  color: "#016665",
};

export const containedButtonStyle = {
  background:
    "linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%), #00B388",
  borderRadius: "15px",
  color: "#FFFFFF",
  width: "132px",
  height: "41px",

  "&.Mui-disabled": {
    background:
      "linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%), #BDBDBD",
    color: "#FFFFFF",
  },
};

export const textButtonStyle = {
  color: "#016665",
  fontWeight: 400,
  textDecoration: "underline",
};
