import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const IdentificationMethodPage = () => {
  return (
    <Box>
      <Grid container sx={{ p: "40px" }}>
        <Grid item xs={12} sx={{ mb: "32px" }}>
          <h1>類別一｜公務車 (汽油)</h1>
          <p>請提供每個月加油總公升數或總金額，並上傳當月發票作為佐證資料。</p>
        </Grid>
        <Grid item xs={12} sx={{ mb: "32px" }}>
          <Grid container>
            <Grid item xs={12}>
              <h4>填寫盤查表單</h4>
            </Grid>
          </Grid>
        </Grid>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left" sx={{ width: "10%" }}>
                  月份
                </TableCell>
                <TableCell align="left" sx={{ width: "10%" }}>
                  油種
                </TableCell>
                <TableCell align="left" sx={{ width: "45%" }}>
                  單位
                </TableCell>
                <TableCell align="left" sx={{ width: "45%" }}>
                  數量
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array(12)
                .fill(1)
                .map((_, index) => (
                  <TableRow key={index} sx={{}}>
                    <TableCell sx={{ width: "5%" }}>{`${
                      index + 1
                    }月`}</TableCell>
                    <TableCell sx={{ width: "5%" }}>汽油</TableCell>
                    <TableCell sx={{ width: "45%" }}>
                      <TextField select size={"small"} fullWidth>
                        <MenuItem value="1">公升數</MenuItem>
                        <MenuItem value="2">金額</MenuItem>
                      </TextField>
                    </TableCell>
                    <TableCell sx={{ width: "45%" }}>
                      <TextField size={"small"} label="公升數" fullWidth />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Box>
  );
};

export default IdentificationMethodPage;
