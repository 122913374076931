import { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";

import * as style from "../styles";

const ReferenceInfoUpload = () => {
  const [numOfFileRows] = useState(2);
  const [numOfFolderRows] = useState(2);

  return (
    <Box
      sx={{
        backgroundColor: "#ffffff",
        padding: "36px",
        zIndex: 1,
        borderRadius: "20px",
      }}>
      <Box>
        <Typography
          sx={(theme) => ({
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "20px",
            lineHeight: "160%",
            letterSpacing: "0.15px",
            color: theme.palette.primary.dark,
          })}
          variant="h6">
          上傳佐證資料
        </Typography>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: "14px",
          }}>
          檔案限制：10MB 以內的 jpg, jpeg, png, doc, xls
          等檔案，上傳時請留意檔名不可重複。
        </Typography>
      </Box>
      <Box>
        <TableContainer sx={{ width: "50vw" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead
              sx={(theme) => ({
                "& .MuiTableRow-root": {
                  "& .MuiTableCell-root": {
                    backgroundColor: "rgba(0,0,0,0)",
                    color: theme.palette.primary.dark,
                    fontWeight: 600,
                    padding: "8px",
                  },
                },
              })}>
              <TableRow>
                <TableCell
                  sx={{
                    width: `5%`,
                  }}>
                  批次處理
                </TableCell>
                <TableCell
                  sx={{
                    width: `10%`,
                  }}>
                  資料夾名稱
                </TableCell>
                <TableCell
                  sx={{
                    width: `45%`,
                  }}></TableCell>
                <TableCell
                  sx={{
                    width: `10%`,
                  }}>
                  編輯資料夾
                </TableCell>
                <TableCell
                  sx={{
                    width: `12%`,
                  }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array(numOfFileRows)
                .fill(1)
                .map((_, index) => {
                  return (
                    <TableRow hover tabIndex={-1} key={index}>
                      <TableCell
                        sx={{
                          width: `5%`,
                        }}>
                        <Checkbox />
                      </TableCell>
                      <TableCell
                        sx={{
                          width: `10%`,
                        }}>
                        {index + 1}月份
                      </TableCell>
                      <TableCell
                        sx={{
                          width: `45%`,
                        }}></TableCell>
                      <TableCell
                        sx={{
                          width: `10%`,
                        }}>
                        <Button
                          sx={(theme) => ({
                            ...style.textButtonStyle,
                            color: theme.palette.primary.dark,
                          })}>
                          重新命名
                        </Button>
                      </TableCell>
                      <TableCell
                        sx={{
                          width: `12%`,
                        }}>
                        <Button
                          sx={(theme) => ({
                            ...style.textButtonStyle,
                            color: theme.palette.error.main,
                          })}>
                          刪除資料夾
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box>
          <Button sx={style.textButtonStyle}>+ 新增一列</Button>
        </Box>
      </Box>
      <Box>
        <TableContainer sx={{ width: "50vw" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead
              sx={(theme) => ({
                "& .MuiTableRow-root": {
                  "& .MuiTableCell-root": {
                    backgroundColor: "rgba(0,0,0,0)",
                    color: theme.palette.primary.dark,
                    fontWeight: 600,
                    padding: "8px",
                  },
                },
              })}>
              <TableRow>
                <TableCell
                  sx={{
                    width: `5%`,
                  }}>
                  批次處理
                </TableCell>
                <TableCell
                  sx={{
                    width: `10%`,
                  }}>
                  檔名
                </TableCell>
                <TableCell
                  sx={{
                    width: `45%`,
                  }}></TableCell>
                <TableCell
                  sx={{
                    width: `10%`,
                  }}>
                  上傳日期
                </TableCell>
                <TableCell
                  sx={{
                    width: `12%`,
                  }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array(numOfFolderRows)
                .fill(1)
                .map((_, index) => {
                  return (
                    <TableRow hover tabIndex={-1} key={index}>
                      <TableCell
                        sx={{
                          width: `5%`,
                        }}>
                        <Checkbox />
                      </TableCell>
                      <TableCell
                        sx={{
                          width: `10%`,
                        }}>
                        {index + 1}月份
                      </TableCell>
                      <TableCell
                        sx={{
                          width: `45%`,
                        }}></TableCell>
                      <TableCell
                        sx={{
                          width: `14%`,
                        }}>
                        2022 - 04 - 01
                      </TableCell>
                      <TableCell
                        sx={{
                          width: `12%`,
                        }}>
                        <Button
                          sx={(theme) => ({
                            ...style.textButtonStyle,
                            color: theme.palette.error.main,
                          })}>
                          刪除資料夾
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box>
          <Button sx={style.textButtonStyle}>+ 新增一列</Button>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: "16px",
        }}>
        <Button sx={style.containedButtonStyle}>下載已選取資料</Button>
        <Button
          sx={(theme) => ({
            ...style.containedButtonStyle,
            background:
              "linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%), #FF8468;",
          })}>
          刪除已選取資料
        </Button>
        <Button sx={style.containedButtonStyle}>關閉</Button>
      </Box>
    </Box>
  );
};

export default ReferenceInfoUpload;
