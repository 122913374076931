import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
// import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
// import ProjectTable from "../components/Table";
import * as style from "../styles";

interface Item {
  value: string;
  width: string;
  isSorted?: boolean;
}

export type CarbonEmissionDataType = {
  id: number;
  year: Item;
  company: Item;
  branch: Item;
  status: Item;
  participants:
    | Item
    | {
        value: ["張特助", "王經理"];
      };
  actions: { name: string; status: string; width: string; func?: Function }[];
};

const CarbonEmissionProjectListPage = () => {
  const navigate = useNavigate();
  const headers = [
    { value: "年份", width: "5", isSorted: true },
    { value: "廠商", width: "10", isSorted: true },
    { value: "分廠", width: "10", isSorted: true },
    { value: "專案狀態", width: "7", isSorted: true },
    { value: "參與人員或角色", width: "15", isSorted: false },
    { value: "", width: "7", isSorted: false },
    { value: "", width: "15", isSorted: false },
    { value: "", width: "15", isSorted: false },
    { value: "", width: "10", isSorted: false },
    { value: "", width: "10", isSorted: false },
  ];
  const rows: CarbonEmissionDataType[] = [
    {
      id: 1,
      year: {
        value: "2023",
        width: "5",
      },
      company: {
        value: "台灣電力公司",
        width: "10",
        isSorted: true,
      },
      branch: {
        value: "台北分公司",
        width: "10",
        isSorted: true,
      },
      status: {
        value: "啟用",
        width: "7",
        isSorted: true,
      },
      participants: {
        value: ["張特助", "王經理"],
        width: "15",
        isSorted: false,
      },
      actions: [
        {
          name: "匯出數據清冊",
          status: "success",
          width: "15",
        },
        { name: "編輯專案權限", status: "success", width: "15" },
        {
          name: "進入專案",
          status: "success",
          width: "10",
          func: () => navigate("/carb-emission/business-car-gas"),
        },
        { name: "刪除專案", status: "error", width: "10" },
      ],
    },
    {
      id: 2,
      year: {
        value: "2023",
        width: "5",
      },
      company: {
        value: "台灣電力公司",
        width: "10",
      },
      branch: {
        value: "台北分公司",
        width: "10",
      },
      status: {
        value: "啟用",
        width: "7",
      },
      participants: {
        value: ["張特助", "王經理"],
        width: "15",
      },
      actions: [
        {
          name: "匯出數據清冊",
          status: "success",
          width: "15",
        },
        { name: "編輯專案權限", status: "success", width: "15" },
        {
          name: "進入專案",
          status: "success",
          width: "10",
          func: () => navigate("/carb-emission/business-car-gas"),
        },
        { name: "刪除專案", status: "error", width: "10" },
      ],
    },
  ];
  return (
    <Box
      sx={{
        background: "linear-gradient(180deg, #F7FFF8 60.35%, #D2FFD7 111.48%)",
        width: "100vw",
        height: "calc(100vh - 186px)",
        padding: "40px",
      }}>
      <Box sx={{ paddingBottom: "40px", width: "calc(100vw - 80px)" }}>
        <Typography variant="h2" sx={style.headerTextStyle}>
          溫室氣體盤查整合｜專案列表
        </Typography>
      </Box>
      <TableContainer sx={{ width: "calc(100vw - 80px)" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead
            sx={(theme) => ({
              "& .MuiTableRow-root": {
                "& .MuiTableCell-root": {
                  backgroundColor: "rgba(0,0,0,0)",
                  color: theme.palette.primary.main,
                  fontWeight: 400,
                  padding: "8px",
                },
              },
            })}>
            <TableRow>
              {headers.map((col) => (
                <TableCell
                  key={col.value}
                  sx={{
                    width: `${col.width}%`,
                  }}>
                  <Box sx={{ display: "flex" }}>
                    <label>{col.value}</label>
                    {col.isSorted && <KeyboardArrowUpIcon />}
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody
            sx={(theme) => ({
              "& .MuiTableRow-root": {
                "& .MuiTableCell-root": {
                  color: theme.palette.primary.dark,
                  fontWeight: 500,
                  padding: "8px",
                },
              },
            })}>
            {rows.map((row: any) => {
              return (
                <TableRow hover tabIndex={-1} key={row.id}>
                  <TableCell
                    sx={{
                      width: `${row.year.width}%`,
                    }}>
                    {row.year.value}
                  </TableCell>
                  <TableCell
                    sx={{
                      width: `${row.year.width}%`,
                    }}>
                    {row.company.value}
                  </TableCell>
                  <TableCell
                    sx={{
                      width: `${row.year.width}%`,
                    }}>
                    {row.branch.value}
                  </TableCell>
                  <TableCell
                    sx={{
                      width: `${row.year.width}%`,
                    }}>
                    {row.status.value}
                  </TableCell>
                  <TableCell
                    sx={{
                      width: `${row.year.width}%`,
                    }}>
                    {row.participants.value.join(", ")}
                  </TableCell>
                  <TableCell
                    sx={{
                      width: `8%`,
                    }}></TableCell>
                  {row.actions.map((action: any) => (
                    <TableCell
                      key={action.name}
                      sx={{
                        width: `${action.width}%`,
                      }}>
                      <Button
                        sx={(theme) => ({
                          ...style.textButtonStyle,
                          color:
                            action.status === "success"
                              ? theme.palette.primary.main
                              : theme.palette.error.main,
                        })}
                        onClick={action.func ? action.func : () => {}}>
                        {action.name}
                      </Button>
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default CarbonEmissionProjectListPage;
