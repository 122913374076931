import "./App.css";
import { Route, Routes } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import Login from "./pages/Login";
import Home from "./pages/Home";
import CarbonEmissionPage from "./pages/CarbonEmissionPage";
import CarbonEmissionProjectListPage from "./pages/CarbonEmissionProjectListPage";
import BusinessCarGas from "./pages/CarbonEmission/BusinessCardGas";
import Refrigerant from "./pages/CarbonEmission/Refrigerant";
import CarbonFootPrintProjectListPage from "./pages/CarbonFootPrintProjectListPage";
import ProductInfo from "./pages/CarbonFootprint/ProductInfo";
import WastedPhase from "./pages/CarbonFootprint/WastedPhase";
import ManufacturingPhase from "./pages/CarbonFootprint/ManufacturingPhase";
import Other from "./pages/CarbonFootprint/Other";
import MaterialPhase from "./pages/CarbonFootprint/MaterialPhase";
import EnergyManagementPage from "./pages/EnergyManagementPage";
import Dashboard from "./pages/EnergyManagement/Dashboard";
import EnergyManagementProjectListPage from "./pages/EnergyManagementProjectListPage";
import IdentificationMethodPage from "./pages/CarbonEmission/IdentificationMethodPage";
// import EnergyRecordPage from "./pages/PowerManagementDashboard";
import ScaleDefinitionPage from "./pages/CarbonEmission/ScaleDefinitionPage";
import StrategyFormPage from "./pages/CarbonEmission/StrategyFormPage";
import Demo from "./pages/DemoDashboard";
import GeoGraphic from "./pages/DemoDashboardGeoGraphic";
import Network from "./pages/DemoDashboardNetwork";

import Menu from "./components/Menu";
import Header from "./components/Header";

let theme = createTheme({
  palette: {
    primary: {
      main: "#00b388",
      light: "#e2f1ee",
      dark: "#016665",
      contrastText: "#000000",
    },
    error: {
      main: "#FF8468",
    },
  },
  // button: {
  //   contained: {
  //     background:
  //       "linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%), #00B388",
  //     borderRadius: "15px",
  //   },
  // },
});

declare module "@mui/material/styles" {
  interface Theme {
    palette: {
      primary: {
        main: string;
        light: string;
        dark: string;
        contrastText: string;
        white: string;
      };
      error: {
        main: string;
      };
    };
    button: {
      contained: {
        background: string;
        borderRadius: string;
      };
    };
  }

  // interface ThemeOptions {
  //   palette?: {
  //     primary?: {
  //       main?: string;
  //       light?: string;
  //       dark?: string;
  //       contrastText?: string;
  //     };
  //   };
  // }
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          maxWidth: "100vw",
          overflowX: "hidden",
        }}>
        <Grid container xs={12} direction={"row"}>
          <Header />
          <Menu />
        </Grid>
        <Grid container xs={12} direction={"row"}>
          <Grid
            item
            xs={12}
            sx={{ backgroundColor: theme.palette.primary.light }}>
            <Routes>
              <Route path="demo-dashboard" element={<Demo />} />
              <Route
                path="demo-dashboard-geographic"
                element={<GeoGraphic />}
              />
              <Route path="demo-dashboard-network" element={<Network />} />
              <Route path="energy-management">
                <Route path="dashboard" element={<Dashboard />} />
              </Route>
              <Route path="carb-emission">
                <Route path="business-car-gas" element={<BusinessCarGas />} />
                <Route path="refrigerant" element={<Refrigerant />} />
                <Route path="strategy-form" element={<StrategyFormPage />} />
                <Route
                  path="identification-method"
                  element={<IdentificationMethodPage />}
                />
                <Route
                  path="scale-definition"
                  element={<ScaleDefinitionPage />}
                />
              </Route>
              <Route path="carb-footprint">
                <Route path="product-info" element={<ProductInfo />} />
                <Route
                  path="manufacturing-phase"
                  element={<ManufacturingPhase />}
                />
                <Route path="material-phase" element={<MaterialPhase />} />
                <Route path="wasted-phase" element={<WastedPhase />} />
                <Route path="other" element={<Other />} />
              </Route>
              <Route path="/login" element={<Login />} />
              <Route path="/" element={<Home />} />
              <Route path="/carbon-emission" element={<CarbonEmissionPage />} />
              <Route
                path="/carbon-emission-project-list"
                element={<CarbonEmissionProjectListPage />}
              />
              <Route
                path="/carbon-footprint-project-list"
                element={<CarbonFootPrintProjectListPage />}
              />
              {/* <Route path="/energy-record" element={<EnergyRecordPage />} /> */}

              <Route
                path="/energy-management"
                element={<EnergyManagementPage />}
              />
              <Route
                path="/energy-management-project-list"
                element={<EnergyManagementProjectListPage />}
              />
              <Route path="*" element={<Home />} />
            </Routes>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
}

export default App;
