import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { useMatch, useNavigate } from "react-router-dom";

import logo from "../assets/images/logo.png";
import * as style from "../styles";

const Header = () => {
  let isLogin = useMatch("/login");
  let isHome = useMatch("/");
  const navigate = useNavigate();

  return (
    <header>
      <Box>
        <Grid
          container
          xs={12}
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{
            width: "100vw",
            background: "#ffffff",
            position: "relative",
            zIndex: 25,
          }}>
          <Grid
            item
            sx={{ p: "30px", cursor: "pointer" }}
            onClick={() => navigate("/")}>
            <div style={{ width: "260px", height: "53px" }}>
              <img alt={"logo"} src={logo} />
            </div>
          </Grid>
          <Grid item sx={isLogin ? { display: "none" } : { p: "30px" }}>
            <Button
              sx={(theme) =>
                isHome
                  ? {
                      display: "none",
                    }
                  : {
                      ...style.textButtonStyle,
                      color: theme.palette.primary.main,
                    }
              }
              onClick={() => navigate("/")}>
              返回專案列表
            </Button>
            <Button
              variant="contained"
              sx={{
                color: "#fff",
                width: "96px",
                height: "41px",
                background:
                  "linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%), #00B388",
                borderRadius: "15px",
              }}>
              登出
            </Button>
          </Grid>
        </Grid>
      </Box>
    </header>
  );
};

export default Header;
