import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import MenuItem from "@mui/material/MenuItem";


const IdentificationMethodPage = () => {
  return (
    <Box>
      <Grid container sx={{p:"40px"}}>
        <Grid item xs={12} sx={{mb:"32px"}}>
          <h1>鑑別方法</h1>
          <p>請透過間接溫室氣體排放四項顯著性評分準則「排放量大小」、「對企業影響程度」、「利害關係者」、「風險或機會」準則進行評估其排放顯著性為「高」或「低」以及「不適用」，將2項(含)以上被評估為「高」的排放源列為重大間接排放源。</p>
        </Grid>
        <Grid item xs={12} sx={{mb:"32px"}}>
          <Grid container>
            <Grid item xs={12}>
              <h4>能源類：輸入電力排放</h4>
            </Grid>
            <Grid container xs={12} sx={{width: "100%", mb:1}}>
              <FormControl sx={{width: "100%"}}>
              <FormLabel sx={{mb:2}}>外購電力</FormLabel>
                <Grid container direction={"row"} xs={12} sx={{width: "100%"}} spacing={2}>
                  <Grid item xs={3}>
                    <TextField 
                      label="排放量大小"
                      value={"高"}
                      size="small"
                      sx={{backgroundColor:"white", width:"100%"}}
                      select
                    >
                      <MenuItem value="高">高</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField 
                      label="對企業影響程度"
                      value={"低"}
                      size="small"
                      sx={{backgroundColor:"white", width:"100%"}}
                      select
                    >
                      <MenuItem value="低">低</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField 
                      label="利害關係者"
                      value={"高"}
                      size="small"
                      sx={{backgroundColor:"white", width:"100%"}}
                      select
                    >
                      <MenuItem value="高">高</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField 
                      label="風險或機會"
                      value={"不適用"}
                      size="small"
                      sx={{backgroundColor:"white", width:"100%"}}
                      select
                    >
                      <MenuItem value="不適用">不適用</MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>
            <Grid container xs={12} sx={{width: "100%"}}>
              <FormControl sx={{width: "100%"}}>
              <FormLabel sx={{mb:2}}>輸入能源排放</FormLabel>
                <Grid container direction={"row"} xs={12} sx={{width: "100%"}} spacing={2}>
                  <Grid item xs={3}>
                    <TextField 
                      label="排放量大小"
                      value={"低"}
                      size="small"
                      sx={{backgroundColor:"white", width:"100%"}}
                      select
                    >
                      <MenuItem value="低">低</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField 
                      label="對企業影響程度"
                      value={"低"}
                      size="small"
                      sx={{backgroundColor:"white", width:"100%"}}
                      select
                    >
                      <MenuItem value="低">低</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField 
                      label="利害關係者"
                      value={"高"}
                      size="small"
                      sx={{backgroundColor:"white", width:"100%"}}
                      select
                    >
                      <MenuItem value="高">高</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField 
                      label="風險或機會"
                      value={"不適用"}
                      size="small"
                      sx={{backgroundColor:"white", width:"100%"}}
                      select
                    >
                      <MenuItem value="不適用">不適用</MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{mb:"40px"}}>

        <Grid container>
            <Grid item xs={12}>
              <h4>交通類：運輸間接排放</h4>
            </Grid>
            <Grid container xs={12} sx={{width: "100%", mb:1}}>
              <FormControl sx={{width: "100%"}}>
              <FormLabel sx={{mb:2}}>上游的運輸與配送排放</FormLabel>
                <Grid container direction={"row"} xs={12} sx={{width: "100%"}} spacing={2}>
                  <Grid item xs={3}>
                    <TextField 
                      label="排放量大小"
                      value={"高"}
                      size="small"
                      sx={{backgroundColor:"white", width:"100%"}}
                      select
                    >
                      <MenuItem value="高">高</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField 
                      label="對企業影響程度"
                      value={"低"}
                      size="small"
                      sx={{backgroundColor:"white", width:"100%"}}
                      select
                    >
                      <MenuItem value="低">低</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField 
                      label="利害關係者"
                      value={"高"}
                      size="small"
                      sx={{backgroundColor:"white", width:"100%"}}
                      select
                    >
                      <MenuItem value="高">高</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField 
                      label="風險或機會"
                      value={"不適用"}
                      size="small"
                      sx={{backgroundColor:"white", width:"100%"}}
                      select
                    >
                      <MenuItem value="不適用">不適用</MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>
            <Grid container xs={12} sx={{width: "100%", mb:1}}>
              <FormControl sx={{width: "100%"}}>
              <FormLabel sx={{mb:2}}>下游的運輸與配送排放</FormLabel>
                <Grid container direction={"row"} xs={12} sx={{width: "100%"}} spacing={2}>
                  <Grid item xs={3}>
                    <TextField 
                      label="排放量大小"
                      value={"低"}
                      size="small"
                      sx={{backgroundColor:"white", width:"100%"}}
                      select
                    >
                      <MenuItem value="低">低</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField 
                      label="對企業影響程度"
                      value={"低"}
                      size="small"
                      sx={{backgroundColor:"white", width:"100%"}}
                      select
                    >
                      <MenuItem value="低">低</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField 
                      label="利害關係者"
                      value={"高"}
                      size="small"
                      sx={{backgroundColor:"white", width:"100%"}}
                      select
                    >
                      <MenuItem value="高">高</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField 
                      label="風險或機會"
                      value={"不適用"}
                      size="small"
                      sx={{backgroundColor:"white", width:"100%"}}
                      select
                    >
                      <MenuItem value="不適用">不適用</MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>
            <Grid container xs={12} sx={{width: "100%", mb:1}}>
              <FormControl sx={{width: "100%"}}>
              <FormLabel sx={{mb:2}}>員工通勤排放</FormLabel>
                <Grid container direction={"row"} xs={12} sx={{width: "100%"}} spacing={2}>
                  <Grid item xs={3}>
                    <TextField 
                      label="排放量大小"
                      value={"低"}
                      size="small"
                      sx={{backgroundColor:"white", width:"100%"}}
                      select
                    >
                      <MenuItem value="低">低</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField 
                      label="對企業影響程度"
                      value={"低"}
                      size="small"
                      sx={{backgroundColor:"white", width:"100%"}}
                      select
                    >
                      <MenuItem value="低">低</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField 
                      label="利害關係者"
                      value={"高"}
                      size="small"
                      sx={{backgroundColor:"white", width:"100%"}}
                      select
                    >
                      <MenuItem value="高">高</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField 
                      label="風險或機會"
                      value={"不適用"}
                      size="small"
                      sx={{backgroundColor:"white", width:"100%"}}
                      select
                    >
                      <MenuItem value="不適用">不適用</MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>
            <Grid container xs={12} sx={{width: "100%", mb:1}}>
              <FormControl sx={{width: "100%"}}>
              <FormLabel sx={{mb:2}}>客戶與訪客運輸排放</FormLabel>
                <Grid container direction={"row"} xs={12} sx={{width: "100%"}} spacing={2}>
                  <Grid item xs={3}>
                    <TextField 
                      label="排放量大小"
                      value={"低"}
                      size="small"
                      sx={{backgroundColor:"white", width:"100%"}}
                      select
                    >
                      <MenuItem value="低">低</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField 
                      label="對企業影響程度"
                      value={"低"}
                      size="small"
                      sx={{backgroundColor:"white", width:"100%"}}
                      select
                    >
                      <MenuItem value="低">低</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField 
                      label="利害關係者"
                      value={"高"}
                      size="small"
                      sx={{backgroundColor:"white", width:"100%"}}
                      select
                    >
                      <MenuItem value="高">高</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField 
                      label="風險或機會"
                      value={"不適用"}
                      size="small"
                      sx={{backgroundColor:"white", width:"100%"}}
                      select
                    >
                      <MenuItem value="不適用">不適用</MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>
            <Grid container xs={12} sx={{width: "100%", mb:1}}>
              <FormControl sx={{width: "100%"}}>
              <FormLabel sx={{mb:2}}>商務差旅排放</FormLabel>
                <Grid container direction={"row"} xs={12} sx={{width: "100%"}} spacing={2}>
                  <Grid item xs={3}>
                    <TextField 
                      label="排放量大小"
                      value={"低"}
                      size="small"
                      sx={{backgroundColor:"white", width:"100%"}}
                      select
                    >
                      <MenuItem value="低">低</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField 
                      label="對企業影響程度"
                      value={"低"}
                      size="small"
                      sx={{backgroundColor:"white", width:"100%"}}
                      select
                    >
                      <MenuItem value="低">低</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField 
                      label="利害關係者"
                      value={"高"}
                      size="small"
                      sx={{backgroundColor:"white", width:"100%"}}
                      select
                    >
                      <MenuItem value="高">高</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField 
                      label="風險或機會"
                      value={"不適用"}
                      size="small"
                      sx={{backgroundColor:"white", width:"100%"}}
                      select
                    >
                      <MenuItem value="不適用">不適用</MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default IdentificationMethodPage;
